import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    square: {
        backgroundColor: theme.palette.primary.main,
        width: '30px',
        height: '30px',
        position: 'absolute',
    },
}));

interface KolulaSquareProps {
    posX: number;
    posY: number;
}

const KolulaBoxSquare = (props: KolulaSquareProps) => {
    const classes = useStyles();

    return (
        <Box
            className={classes.square}
            style={{
                left: props.posX,
                top: props.posY,
            }}
        />
    );
};

export default KolulaBoxSquare;
