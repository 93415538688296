import {Availabilities, Location, Option, Options} from '../types/location';
import moment from 'moment-timezone';
import {InternalOrder, Order} from '../types/order';
import {Reservation} from '../types/bookingCreation';
import {IndividualRental} from '../types/individualRental';

export const processLocationsData = (data: any[]): Location[] => {
    return data
        .map((locationData: any): Location => ({
            id: Number(locationData.locationID),
            name: locationData.regiondo_data.name,
            description: locationData.regiondo_data.short_description,
            containerType: locationData.containerType,
            preparationTime: locationData.preparationTime,
            followUpTime: locationData.followUpTime,
            gmapsQuery: locationData.regiondo_data.location_address,
            coordinates: {
                lat: Number(locationData.regiondo_data.geo_lat),
                lon: Number(locationData.regiondo_data.geo_lon),
            },
            status: {
                bookable: locationData.status.bookable,
                notification: !locationData.status.notification ? undefined : {
                    type: locationData.status.notification.type,
                    text: locationData.status.notification.text,
                },
            },
            useableByUsc: Boolean(locationData.useableByUsc),
            multipleBoardTypes: locationData.multipleBoardTypes !== false,
            accessories: locationData.accessories || [],
        }));
};

export const processAvailabilityData = (data: { regiondo_variation_availability_data: any[] }): Availabilities => {
    const slots: Availabilities = {};

    data.regiondo_variation_availability_data.forEach(variationAvailabilities => {
        variationAvailabilities.forEach((availability: any) => {
            const date = moment(availability.start_date_time);
            const dateKey = date.format('YYYY-MM-DD');
            const timeKey = date.format('HH:mm');

            slots[dateKey] = {
                ...(slots[dateKey] || {}),
                [timeKey]: {
                    ...(slots[dateKey] ? slots[dateKey][timeKey] || {} : {}),
                    [availability.regiondo_variation_id]: availability.qty_available_by_option,
                },
            };
        });
    });

    return slots;
};

export const processOptionData = (data: { regiondo_options_data: any[] }): Options => {
    const options: Options = {};

    Object.values(data.regiondo_options_data).forEach(optionData => {
        Object.values(optionData).forEach((optionEntry: any) => {
            const option: Option = {
                id: optionEntry.option_id,
                price: optionEntry.original_price,
                duration: getOptionDurationInHours(optionEntry.duration_type, optionEntry.duration_value),
                name: optionEntry.name,
                description: optionEntry.description,
            };

            options[optionEntry.variation_id] = {
                ...(options[optionEntry.variation_id] || {}),
                [optionEntry.option_id]: option,
            };
        });
    });

    return options;
};

export const getOptionDurationInHours = (durationType: string, durationValue: string): number => {
    switch (durationType) {
        case 'hour':
            return parseFloat(durationValue);
        case 'minute':
            return parseFloat(durationValue) / 60.0;
        default:
            throw new Error(`Unknown duration type ${durationType} found`);
    }
};

/**
 * @todo fix hardcoded timezone?
 * @param data
 */
export const processReservationData = (data: any): Reservation => {
    const hasValidDiscount = Boolean(data.discountInfo.length > 0 && data.discountInfo[0].is_valid);

    return {
        reservationId: data.reservationID,
        discount: !hasValidDiscount ? null : {
            code: data.discountInfo[0].code,
            amount: data.discountInfo[0].amount,
        },
        payment: {
            items: data.reservation_items.map((itemData: any) => ({
                optionId: itemData.option_id,
                quantity: itemData.quantity,
                amount: itemData.regiondoHoldResponse.totals.grand_total,
            })),
            amount: data.payment.requested_amount,
            tax: data.payment.tax.value,
        },
        date: moment.tz(data.reservedDate, 'Europe/Berlin').toDate(),
    };
};

export const processOrderData = (data: any): InternalOrder => {
    const groupedItems = groupOrderItems(data);

    return groupedItems.map((items: any[]): Order => {
        const locationTimeZone = items[0].kolula_location_timeZone;
        const startDateTime = moment.tz(items[0].event_date_time, locationTimeZone).toDate();
        let endDateTime = null;

        if (items[0].ticket_codes.length > 0) {
            endDateTime = moment.tz(
                items[0].ticket_codes[0].expiry_date + ' ' + items[0].ticket_codes[0].expiry_time,
                locationTimeZone,
            ).toDate();
        }

        return {
            id: `${data.order_number}_${items.map(item => item.unique_item_id).join('_')}`,
            orderId: data.order_number,
            locationId: Number(items[0].kolula_locationID),
            locationName: items[0].ticket_name,
            startDateTime,
            endDateTime,
            locationTimeOffset: calculateLocationTimeOffset(items[0].kolula_location_currentTime),
            status: items.find((item: any) => item.status === 'processing') ? 'processing' : 'approved',
            boards: items.map((item: any) => ({
                type: item.ticket_option,
                quantity: item.ticket_qty,
            })),
            rental: {
                startedAt: data.rental?.startedAt ? new Date(data.rental.startedAt * 1000) : null,
                finishedAt: data.rental?.finishedAt ? new Date(data.rental.finishedAt) : null,
            },
            isUscOrder: data.isUscOrder === true,
        };
    });
};

export const processIndividualRentalData = (data: any): IndividualRental => {
    return {
        token: data.token,
        tokenValidUntil: moment.tz(data.tokenValidUntil, 'Europe/Berlin').toDate(),
        locationId: Number(data.locationId),
        door: Number(data.doorId),
        validUntil: moment.tz(data.validUntil, 'Europe/Berlin').toDate(),
    };
};

/**
 * Estimates the time offset between the client and the location in milliseconds
 *
 * @param locationCurrentTime
 */
const calculateLocationTimeOffset = (locationCurrentTime: string): number => {
    const locationTime = moment(locationCurrentTime).toDate();
    return locationTime.getTime() - new Date().getTime() + 100;
};

/**
 * Group items by
 * - Location
 * - Date & Time
 * - Duration (if available; no problem if duration is not available since the booking can't be used before it is)
 *
 * @param data
 */
const groupOrderItems = (data: any): any[] => {
    const itemGroups: Record<string, any[]> = {};

    data.items.forEach((item: any) => {
        const locationId = item.kolula_locationID;
        const locationTimeZone = item.kolula_location_timeZone;

        const startDateTime = moment.tz(item.event_date_time, locationTimeZone);
        const endDateTime = item.ticket_codes.length > 0
            ? moment.tz(item.ticket_codes[0].expiry_date + ' ' + item.ticket_codes[0].expiry_time, locationTimeZone)
            : undefined;

        const start = startDateTime.toISOString();
        const end = endDateTime?.toISOString();
        const itemGroupKey = `${locationId}_${start}_${end}`;

        if (!itemGroups[itemGroupKey]) {
            itemGroups[itemGroupKey] = [];
        }

        itemGroups[itemGroupKey].push(item);
    });

    return Object.values(itemGroups);
};
