import {call, fork, put, take} from 'redux-saga/effects';
import Command from '../../action/command';
import {Action} from 'redux-actions';
import {CompleteVippsPaymentPayload} from '../../action/bookingCreationCommand';
import Event from '../../action/event';
import {OrderId} from '../../types/order';
import Api from '../../api';
import {onFetchOrder} from '../fetchOrderFlow';
import {trackGTMPlaceOrder} from '../helpers/gtm';
import {onEnqueueErrorSnackbar} from '../enqueueSnackbarFlow';
import {getI18n} from 'react-i18next';
import Logger from '../../util/logger';
import ErrorTracker from '../../util/errorTracker';

const onCompleteVippsPayment = function*(payload: CompleteVippsPaymentPayload) {
    yield put(Event.BookingCreation.paymentCompletionBegan({}));

    try {
        const orderId: OrderId = yield call(Api.completeVippsPayment, {
            reservationId: payload.reservationId,
        });

        yield put(Event.BookingCreation.paymentCompleted({ orderId }));
        yield fork(onFetchOrder, orderId);
        yield fork(trackGTMPlaceOrder, orderId);
    } catch (error) {
        yield fork(onEnqueueErrorSnackbar, getI18n().t('book_location.error.complete_payment'));
        yield put(Event.BookingCreation.paymentCompletionFailed({}));
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);
    }
};

export function* completeVippsPaymentFlow() {
    while (true) {
        const action: Action<CompleteVippsPaymentPayload> = yield take(
            Command.BookingCreation.completeVippsPayment.toString(),
        );

        yield fork(onCompleteVippsPayment, action.payload);
    }
}
