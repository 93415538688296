import {call, fork, put, select, take} from 'redux-saga/effects';
import Command from '../action/command';
import Logger from '../util/logger';
import Event from '../action/event';
import {Rental} from '../types/order';
import Selector from '../selector';
import Api from '../api';
import ErrorTracker from '../util/errorTracker';
import {onEnqueueErrorSnackbar, onEnqueueSuccessSnackbar} from './enqueueSnackbarFlow';
import {getI18n} from 'react-i18next';

export const onFinishOrder = function*() {
    const rental: Rental|null = yield select(Selector.Order.rental);

    if (!rental) {
        return;
    }

    try {
        yield call(Api.finishOrder, rental.id, rental.token);
    } catch (error) {
        yield fork(onEnqueueErrorSnackbar, getI18n().t('orders.error.finish_booking'), 8000);
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);

        // For now still assume that the order was finished to give the user a option to leave the process
        yield put(Event.Order.orderFinished({}));
        return;
    }

    yield put(Event.Order.orderFinished({}));
    yield fork(onEnqueueSuccessSnackbar, getI18n().t('orders.booking_finished_info'), 8000);
};

export function* finishOrderFlow() {
    while (true) {
        yield take(Command.Order.finishOrder.toString());
        yield fork(onFinishOrder);
    }
}
