import {call, fork, put, select, take} from 'redux-saga/effects';
import Command from '../../action/command';
import Event from '../../action/event';
import Logger from '../../util/logger';
import {onEnqueueErrorSnackbar} from '../enqueueSnackbarFlow';
import {getI18n} from 'react-i18next';
import {onPersistDefaultPersonalData} from '../manageDefaultPersonalDataFlow';
import Api from '../../api';
import Selector from '../../selector';
import {
    BraintreePaymentInformation,
    PaymentInformation,
    PersonalData,
    Reservation,
    StripePaymentInformation,
    VippsPaymentInformation,
} from '../../types/bookingCreation';
import {onFetchOrder} from '../fetchOrderFlow';
import ErrorTracker from '../../util/errorTracker';
import {trackGTMPlaceOrder} from '../helpers/gtm';
import Config from '../../util/config';

const onInitializePayment = function*() {

    const isSinglePaymentProvider = (paymentProvider: string) => {
        return (
            Config.tenantConfig.paymentProvider.length === 1 && 
            Config.tenantConfig.paymentProvider[0] === paymentProvider
        );
    };

    try {
        const isBraintreePayment = isSinglePaymentProvider('braintree');
        let isVippsPayment = isSinglePaymentProvider('vipps');
        let isStripePayment = isSinglePaymentProvider('stripe');
        const hasMultiplePaymentProvider = Config.tenantConfig.paymentProvider.length > 1;
        
        // when multiple payment provider are configured, determine payment provider based user selection
        if (hasMultiplePaymentProvider) {
            const paymentType: string = yield select(Selector.BookingCreation.paymentType);
            isStripePayment = ['googlepay', 'applepay', 'creditcard'].includes(paymentType);
            isVippsPayment = ['vipps'].includes(paymentType);
        }
        if (isBraintreePayment) {
            yield call(initializeBraintreePayment);
        } else if (isVippsPayment) {
            yield call(initializeVippsPayment);
        } else if (isStripePayment) {
            yield call(initializeStripePayment);
        } else {
            // throw new Error('Unknown payment provider');
        }
    } catch (error) {
        yield put(Event.BookingCreation.paymentInitializationFailed({}));
        yield fork(onEnqueueErrorSnackbar, getI18n().t('book_location.error.initialize_payment'));
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);
    }
};

const initializeBraintreePayment = function* () {
    const reservation: Reservation = yield select(Selector.BookingCreation.reservation);
    const personalData: PersonalData = yield select(Selector.BookingCreation.personalData);
    const isPartnerUsc: boolean = yield select(Selector.BookingCreation.isPartnerUsc);

    const paymentInformation: BraintreePaymentInformation = yield call(Api.initializeBraintreePayment, {
        reservationId: reservation.reservationId,
        contact: {
            ...personalData,
            uscCustomerId: isPartnerUsc ? personalData.uscCustomerId : undefined,
        },
    });

    yield fork(onPersistDefaultPersonalData);

    const noPaymentNecessary: boolean = yield call(checkAndHandleNoPaymentNecessary, paymentInformation);
    if (noPaymentNecessary) {
        return;
    }

    yield put(Event.BookingCreation.braintreePaymentInitialized({
        braintreePaymentToken: paymentInformation.braintreePaymentToken!,
    }));
};

const initializeVippsPayment = function* () {
    const reservation: Reservation = yield select(Selector.BookingCreation.reservation);
    const personalData: PersonalData = yield select(Selector.BookingCreation.personalData);
    const isPartnerUsc: boolean = yield select(Selector.BookingCreation.isPartnerUsc);

    const paymentInformation: VippsPaymentInformation = yield call(Api.initializeVippsPayment, {
        reservationId: reservation.reservationId,
        contact: {
            ...personalData,
            uscCustomerId: isPartnerUsc ? personalData.uscCustomerId : undefined,
        },
        returnUrl: document.location.origin + '/payments/vipps/return?reservationId=' + reservation.reservationId,
    });

    yield fork(onPersistDefaultPersonalData);

    const noPaymentNecessary: boolean = yield call(checkAndHandleNoPaymentNecessary, paymentInformation);
    if (noPaymentNecessary) {
        return;
    }

    yield put(Event.BookingCreation.vippsPaymentInitialized({
        vippsToken: paymentInformation.token!,
        checkoutFrontendUrl: paymentInformation.checkoutFrontendUrl!,
    }));
};
const initializeStripePayment = function* () {
    const reservation: Reservation = yield select(Selector.BookingCreation.reservation);
    const personalData: PersonalData = yield select(Selector.BookingCreation.personalData);
    const isPartnerUsc: boolean = yield select(Selector.BookingCreation.isPartnerUsc);

    const paymentInformation: StripePaymentInformation = yield call(Api.initializeStripePayment, {
        reservationId: reservation.reservationId,
        contact: {
            ...personalData,
            uscCustomerId: isPartnerUsc ? personalData.uscCustomerId : undefined,
        },
        returnUrl: document.location.origin + '/payments/stripe/return?reservationId=' + reservation.reservationId,
    });

    yield fork(onPersistDefaultPersonalData);

    const noPaymentNecessary: boolean = yield call(checkAndHandleNoPaymentNecessary, paymentInformation);
    if (noPaymentNecessary) {
        return;
    }

    yield put(Event.BookingCreation.stripePaymentInitialized({
        stripeToken: paymentInformation.token!,
        checkoutFrontendUrl: paymentInformation.checkoutFrontendUrl!,
    }));
};

const checkAndHandleNoPaymentNecessary = function* (paymentInformation: PaymentInformation) {
    if (paymentInformation.paymentStatus !== 'NO_PAYMENT') {
        return false;
    }

    const orderId = paymentInformation.orderId!;
    yield put(Event.BookingCreation.paymentCompleted({ orderId }));
    yield fork(onFetchOrder, orderId);
    yield fork(trackGTMPlaceOrder, orderId);
    return true;
};

export function* initializePaymentFlow() {
    while (true) {
        yield take(Command.BookingCreation.initializePaymentProvider.toString());

        yield fork(onInitializePayment);
    }
}
