export const palette = {
    primary: {
        dark: '#00727b',
        main: '#00a4b1',
        light: '#33b6c0',
        contrastText: '#ffffff',
    },
    secondary: {
        dark: '#b2451c',
        main: '#ff6328',
        light: '#ff8253',
        contrastText: '#ffffff',
    },
    background: {
        default: '#ffffff',
    },
    action: {
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(70, 70, 70, 0.20)',
    },
    divider: '#a0a6a7',
};
