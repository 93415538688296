import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import de from './de.json';
import en from './en.json';
import nb from './nb.json';
import Config from '../util/config';

export const supportedLanguages = ['de', 'en', 'nb'];

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['querystring', 'localStorage', 'navigator'],
        },
        resources: {
            de: { translations: de },
            en: { translations: en },
            nb: { translations: nb },
        },
        supportedLngs: supportedLanguages,
        fallbackLng: 'en',
        debug: true,
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
            escapeValue: false,
            format: (value, format) => {
                if (typeof value === 'number' && format === 'number') return formatNumber(value);
                if (typeof value === 'number' && format === 'money') return formatMoney(value);
                return value;
            },
        },
    });

export const persistLanguage = (language: string): void => {
    if (!supportedLanguages.includes(language)) {
        return;
    }

    localStorage.setItem('i18nextLng', language);
};

export const getCurrentLocale = () => {
    switch (i18n.language) {
        case 'de': return 'de-DE';
        case 'nb': return 'nb-NO';
        default: return 'en-US';
    }
};

export const formatNumber = (value: number, options?: Intl.NumberFormatOptions): string =>
    value.toLocaleString(getCurrentLocale(), options);

export const formatMoney = (value: number): string => {
    return Intl.NumberFormat(getCurrentLocale(), {
        style: 'currency',
        currency: Config.tenantConfig.currency,
    }).format(value);
};

export default i18n;
