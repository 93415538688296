import React, {useEffect, useRef} from 'react';
import {CupertinoPane} from 'cupertino-pane';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Location} from '../../../types/location';
import './LocationPane.css';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import {isInStandaloneMode, isIos} from '../../../util/platformDetection';
import LocationDetails from './LocationDetails';
import GTM from '../../../util/gtm';

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
}));

interface LocationPaneProps {
    location: Location|null;
    onClose: () => void;
}

const LocationPane = (props: LocationPaneProps) => {
    const classes = useStyles();
    const drawer = useRef<CupertinoPane>();

    /* Keep a ref to props.location to provide the up-to-date value for the onTransitionEnd callback */
    const locationRef = useRef<Location|null>(props.location);
    locationRef.current = props.location;

    useEffect(() => {
        drawer.current = new CupertinoPane('#locationPane', {
            fastSwipeClose: true,
            fastSwipeSensivity: 30,
            onDidDismiss: props.onClose,
            breaks: {
                bottom: { enabled: true, height: 145 },
            },
            onTransitionEnd: () => {
                const isSwipeTop = drawer.current!.currentBreak() === 'top';

                if (isSwipeTop && locationRef.current) {
                    GTM.trackEecProductDetails(locationRef.current);
                }

                if (!isIos() || !isInStandaloneMode()) {
                    return;
                }

                if (isSwipeTop) {
                    clearAllBodyScrollLocks();
                    disableBodyScroll(document.getElementById('locationPane')!);
                } else {
                    clearAllBodyScrollLocks();
                    disableBodyScroll(document.getElementById('locationPaneDummy')!);
                }
            },
        });

        return () => clearAllBodyScrollLocks();
    }, []);

    useEffect(() => {
        if (!drawer.current) {
            return;
        }

        if (props.location) {
            drawer.current.present({ animate: true });
        } else {
            drawer.current.destroy({ animate: true });
        }
    }, [props.location === null]);

    return (
        <Box id={'locationPane'} className={classes.root}>
            <div id={'locationPaneDummy'} />
            {props.location && <LocationDetails location={props.location} />}
        </Box>
    );
};

export default LocationPane;
