import {createMuiTheme} from '@material-ui/core';

const theme = createMuiTheme();

export const makeTypography = (palette: any) => ({
    fontFamily: [
        "'ballinger'",
        'sans-serif',
    ].join(','),
    h1: {
        fontSize: '20px',
        textTransform: 'uppercase' as 'uppercase',
        fontWeight: 700,
        textAlign: 'center' as 'center',
        [theme.breakpoints.up('md')]: {
            fontSize: '24px',
        },
    },
    h2: {
        fontSize: '19px',
        [theme.breakpoints.up('md')]: {
            fontSize: '22px',
        },
    },
    h3: {
        fontSize: '18px',
        [theme.breakpoints.up('md')]: {
            fontSize: '21px',
        },
    },
    body1: {
        fontSize: '16px',
        [theme.breakpoints.up('md')]: {
            fontSize: '19px',
        },
    },
    body2: {
        fontSize: '13px',
        [theme.breakpoints.up('md')]: {
            fontSize: '15px',
        },
    },
});
