import {createAction} from 'redux-actions';
import {InternalOrder, Order, OrderId, Rental} from '../types/order';
import {IndividualRental} from '../types/individualRental';

export interface OrderFetchingBeganPayload {
}

export interface OrderFetchedPayload {
    internalOrder: InternalOrder;
}

export interface OrderFetchingFailedPayload {
}

export interface OrdersLoadedPayload {
    orders: Order[];
}

export interface OrderStartingBeganPayload {
}

export interface OrderStartedPayload {
    rental: Rental;
    startedTimestamp: Date;
}

export interface OrderStartingFailedPayload {
}

export interface OrderFinishedPayload {
}

export interface OrderFinalizedPayload {
    orderId: OrderId;
}

export interface ReportingDamageBeganPayload {
}

export interface ReportingDamageFailedPayload {
}

export interface DamageReportedPayload {
}

export interface DoorsVisibleTimestampResetPayload {
}

export interface IndividualRentalVerificationBeganPayload {
}

export interface IndividualRentalVerificationCompletedPayload {
    individualRental: IndividualRental;
}

export interface IndividualRentalVerificationFailedPayload {
}

export interface IndividualRentalCompletedPayload {
}

/* eslint-disable max-len */
const OrderEvent = {
    orderFetchingBegan: createAction<OrderFetchingBeganPayload>('@@KL_EVT/ORDER_FETCHING_BEGAN'),
    orderFetched: createAction<OrderFetchedPayload>('@@KL_EVT/ORDER_FETCHED'),
    orderFetchingFailed: createAction<OrderFetchingFailedPayload>('@@KL_EVT/ORDER_FETCHING_FAILED'),
    ordersLoaded: createAction<OrdersLoadedPayload>('@@KL_EVT/ORDERS_LOADED'),
    orderStartingBegan: createAction<OrderStartingBeganPayload>('@@KL_EVT/ORDER_STARTING_BEGAN'),
    orderStarted: createAction<OrderStartedPayload>('@@KL_EVT/ORDER_STARTED'),
    orderStartingFailed: createAction<OrderStartingFailedPayload>('@@KL_EVT/ORDER_STARTING_FAILED'),
    orderFinished: createAction<OrderFinishedPayload>('@@KL_EVT/ORDER_FINISHED'),
    orderFinalized: createAction<OrderFinalizedPayload>('@@KL_EVT/ORDER_FINALIZED'),
    reportingDamageBegan: createAction<ReportingDamageBeganPayload>('@@KL_EVT/REPORTING_DAMAGE_BEGAN'),
    reportingDamageFailed: createAction<ReportingDamageFailedPayload>('@@KL_EVT/REPORTING_DAMAGE_FAILED'),
    damageReported: createAction<DamageReportedPayload>('@@KL_EVT/DAMAGE_REPORTED'),
    doorsVisibleTimestampReset: createAction<DoorsVisibleTimestampResetPayload>('@@KL_EVT/DOORS_VISIBLE_TIMESTAMP_RESET'),

    individualRentalVerificationBegan: createAction<IndividualRentalVerificationBeganPayload>('@@KL_EVT/INDIVIDUAL_RENTAL_VERIFICATION_BEGAN'),
    individualRentalVerificationCompleted: createAction<IndividualRentalVerificationCompletedPayload>('@@KL_EVT/INDIVIDUAL_RENTAL_VERIFICATION_COMPLETED'),
    individualRentalVerificationFailed: createAction<IndividualRentalVerificationFailedPayload>('@@KL_EVT/INDIVIDUAL_RENTAL_VERIFICATION_FAILED'),
    individualRentalCompleted: createAction<IndividualRentalCompletedPayload>('@@KL_EVT/INDIVIDUAL_RENTAL_COMPLETED'),
};

export default OrderEvent;
