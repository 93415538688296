import { call, fork, put, take } from 'redux-saga/effects';
import Command from '../action/command';
import {Location} from '../types/location';
import Api from '../api';
import Event from '../action/event';
import Logger from '../util/logger';
import {onEnqueueErrorSnackbar} from './enqueueSnackbarFlow';
import {getI18n} from 'react-i18next';
import ErrorTracker from '../util/errorTracker';

export const onFetchLocations = function*() {
    try {
        const locations: Location[] = yield call(Api.fetchLocations);
        yield put(Event.Location.locationsFetched({ locations }));
    } catch (error) {
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);
        yield fork(onEnqueueErrorSnackbar, getI18n().t('locations.error.fetch_locations'));
    }
};

export function* fetchLocationsFlow() {
    while (true) {
        yield take(Command.Location.fetchLocations.toString());

        yield fork(onFetchLocations);
    }
}
