import {call, fork, select, take} from 'redux-saga/effects';
import Command from '../action/command';
import {Action} from 'redux-actions';
import Api from '../api';
import Logger from '../util/logger';
import {onEnqueueErrorSnackbar} from './enqueueSnackbarFlow';
import {getI18n} from 'react-i18next';
import {OpenDoorPayload} from '../action/orderCommand';
import Selector from '../selector';
import {Order, OrderId, Rental} from '../types/order';
import ErrorTracker from '../util/errorTracker';

export const onOpenDoor = function*(orderId: OrderId, door: number) {
    const order: Order|null = yield select(Selector.Order.makeOrder(orderId));
    const rental: Rental|null = yield select(Selector.Order.rental);

    if (!rental || !order) {
        yield fork(onEnqueueErrorSnackbar, getI18n().t('use_order.error.open_door'));
        return;
    }

    // @todo check rental order mismatch just to make sure
    // const tokenData = parseJwt(rental.token);

    try {
        yield call(
            Api.openDoor,
            order.locationId,
            door,
            rental.token,
        );
    } catch (error) {
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);
        yield fork(onEnqueueErrorSnackbar, getI18n().t('use_order.error.open_door'));
    }
};

export function* openDoorFlow() {
    while (true) {
        const action: Action<OpenDoorPayload> = yield take(Command.Order.openDoor.toString());

        yield fork(onOpenDoor, action.payload.orderId, action.payload.door);
    }
}
