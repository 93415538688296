import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import HintsDialog from './components/HintsDialog';
import {Link, Table, TableCell, TableHead, TableRow, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import Config from '../../util/config';
import {formatMoney} from '../../i18n/i18n';

const useStyles = makeStyles(theme => ({
    pricesTable: {
        width: '100%',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    tableHeadCell: {
        color: 'inherit',
    },
    tableHeadLeft: {
        fontWeight: 500,
        '& span': {
            whiteSpace: 'nowrap',
        },
    },
    tableCell: {
        whiteSpace: 'nowrap',
    },
    highlightRow: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

interface PriceDialogProps {
    open: boolean;
}

const PriceDialog = (props: PriceDialogProps) => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <HintsDialog open={props.open}>
            <Typography variant={'h1'}>{t('hints.prices.title')}</Typography>
            <Table className={classes.pricesTable}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHeadCell}></TableCell>
                        <TableCell align={'center'} className={classes.tableHeadCell}>
                            {t('hints.prices.mon_fri')}
                        </TableCell>
                        <TableCell align={'center'} className={classes.tableHeadCell}>
                            {t('hints.prices.sat_sun')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                {Config.tenantConfig.prices.map(priceData => (
                    <TableRow
                        key={priceData.duration}
                        className={priceData.bestseller ? classes.highlightRow : undefined}
                    >
                        <TableCell className={classes.tableHeadLeft}>
                            {priceData.bestseller && <>Unser Bestseller:<br /></>}
                            <span>{t('general.hours_val', { count: priceData.duration })}</span>
                        </TableCell>
                        <TableCell align={'center'} className={classes.tableCell}>
                            {t('general.from')} {formatMoney(priceData.weekdayPrice)}
                        </TableCell>
                        <TableCell align={'center'} className={classes.tableCell}>
                            {t('general.from')} {formatMoney(priceData.weekendPrice)}
                        </TableCell>
                    </TableRow>
                ))}
            </Table>
            {Config.tenant === 'kolula' && (
                <Typography variant={'body2'}>
                    Alle Preise verstehen sich inklusive der Mehrwertsteuer. Du kannst übrigens auch Boards für
                    Veranstaltungen und deinen Geburtstag mieten. Schreib uns einfach eine Mail an
                    <Link href={'mailto:support@kolula.com'}> support@kolula.com </Link>
                    – wir melden uns dann bei dir.
                </Typography>
            )}
        </HintsDialog>
    );
};

export default PriceDialog;
