import moment from 'moment';
import {createAction} from 'redux-actions';
import {BraintreePaymentToken, Reservation, StripeToken, VippsToken} from '../types/bookingCreation';
import {OrderId} from '../types/order';

export interface BookingCreationStartedPayload {
    locationId: number;
    multipleBoardTypes: boolean;
    partner?: 'usc';
}

export interface DateSelectedPayload {
    date: moment.Moment|null;
}

export interface TimeSelectedPayload {
    time: string;
}

export interface DurationSelectedPayload {
    duration: number;
}

export interface PaddlerCountSelectedPayload {
    paddlerCount: number;
}

export interface BoardTypeActivatedPayload {
    id: string;
}

export interface BoardTypeDeactivatedPayload {
    id: string;
}

export interface BoardTypeCountChangedPayload {
    id: string;
    count: number;
}

export interface PaymentTypeChangedPayload {
    paymentType: string;
}

export interface ReservationCreatedPayload {
    reservation: Reservation;
}

export interface ReservationRemovedPayload {
}

export interface FirstNameChangedPayload {
    firstName: string;
}

export interface LastNameChangedPayload {
    lastName: string;
}

export interface PhoneChangedPayload {
    phone: string;
}

export interface EmailChangedPayload {
    email: string;
}

export interface NewsletterConsentChangedPayload {
    newsletterConsent: boolean;
}

export interface UscCustomerIdChangedPayload {
    uscCustomerId: string;
}

export interface PersonalDataChangedPayload {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    newsletterConsent: boolean;
    uscCustomerId?: string;
}

export interface CouponRedemptionLoadingPayload {
    loading: boolean;
}

export interface BookingCreationCanceledPayload {
}

export interface BraintreePaymentInitializedPayload {
    braintreePaymentToken: BraintreePaymentToken;
}

export interface VippsPaymentInitializedPayload {
    vippsToken: VippsToken;
    checkoutFrontendUrl: string;
}
export interface StripePaymentInitializedPayload {
    stripeToken: StripeToken;
    checkoutFrontendUrl: string;
}

export interface PaymentInitializationFailedPayload {
}

export interface PaymentInitializationRetryStartedPayload {
}

export interface PaymentCompletionBeganPayload {
}

export interface PaymentCompletedPayload {
    orderId: OrderId;
}

export interface PaymentCompletionFailedPayload {
}

export interface BookingCreationCompletedPayload {
}

/* eslint-disable max-len */
const BookingCreationEvent = {
    bookingCreationStarted: createAction<BookingCreationStartedPayload>('@@KL_EVT/BOOKING_CREATION_STARTED'),
    dateSelected: createAction<DateSelectedPayload>('@@KL_EVT/DATE_SELECTED'),
    timeSelected: createAction<TimeSelectedPayload>('@@KL_EVT/TIME_SELECTED'),
    durationSelected: createAction<DurationSelectedPayload>('@@KL_EVT/DURATION_SELECTED'),
    paddlerCountSelected: createAction<PaddlerCountSelectedPayload>('@@KL_EVT/PADDLER_COUNT_SELECTED'),
    boardTypeActivated: createAction<BoardTypeActivatedPayload>('@@KL_EVT/BOARD_TYPE_ACTIVATED'),
    boardTypeDeactivated: createAction<BoardTypeActivatedPayload>('@@KL_EVT/BOARD_TYPE_DEACTIVATED'),
    boardTypeCountChanged: createAction<BoardTypeCountChangedPayload>('@@KL_EVT/BOARD_TYPE_COUNT_CHANGED'),
    paymentTypeChanged: createAction<PaymentTypeChangedPayload>('@@KL_EVT/PAYMENT_TYPE_CHANGED'),
    navigatedToNextStep: createAction<{}>('@@KL_EVT/NAVIGATED_TO_NEXT_STEP'),
    navigatedToPreviousStep: createAction<{}>('@@KL_EVT/NAVIGATED_TO_PREVIOUS_STEP'),
    reservationCreated: createAction<ReservationCreatedPayload>('@@KL_EVT/RESERVATION_CREATED'),
    reservationRemoved: createAction<ReservationRemovedPayload>('@@KL_EVT/RESERVATION_REMOVED'),
    firstNameChanged: createAction<FirstNameChangedPayload>('@@KL_EVT/FIRST_NAME_CHANGED'),
    lastNameChanged: createAction<LastNameChangedPayload>('@@KL_EVT/LAST_NAME_CHANGED'),
    phoneChanged: createAction<PhoneChangedPayload>('@@KL_EVT/PHONE_CHANGED'),
    emailChanged: createAction<EmailChangedPayload>('@@KL_EVT/EMAIL_CHANGED'),
    newsletterConsentChanged: createAction<NewsletterConsentChangedPayload>('@@KL_EVT/NEWSLETTER_CONSENT_CHANGED'),
    uscCustomerIdChanged: createAction<UscCustomerIdChangedPayload>('@@KL_EVT/USC_CUSTOMER_ID_CHANGED'),
    personalDataChanged: createAction<PersonalDataChangedPayload>('@@KL_EVT/PERSONAL_DATA_CHANGED'),
    couponRedemptionLoading: createAction<CouponRedemptionLoadingPayload>('@@KL_EVT/COUPON_REDEMPTION_LOADING'),
    bookingCreationCanceled: createAction<BookingCreationCanceledPayload>('@@KL_EVT/BOOKING_CREATION_CANCELED'),
    braintreePaymentInitialized: createAction<BraintreePaymentInitializedPayload>('@@KL_EVT/BRAINTREE_PAYMENT_INITIALIZED'),
    vippsPaymentInitialized: createAction<VippsPaymentInitializedPayload>('@@KL_EVT/VIPPS_PAYMENT_INITIALIZED'),
    stripePaymentInitialized: createAction<StripePaymentInitializedPayload>('@@KL_EVT/STRIPE_PAYMENT_INITIALIZED'),
    paymentInitializationFailed: createAction<PaymentInitializationFailedPayload>('@@KL_EVT/PAYMENT_INITIALIZATION_FAILED'),
    paymentInitializationRetryStarted: createAction<PaymentInitializationRetryStartedPayload>('@@KL_EVT/PAYMENT_INITIALIZATION_RETRY_STARTED'),
    paymentCompletionBegan: createAction<PaymentCompletionBeganPayload>('@@KL_EVT/PAYMENT_COMPLETION_BEGAN'),
    paymentCompleted: createAction<PaymentCompletedPayload>('@@KL_EVT/PAYMENT_COMPLETED'),
    paymentCompletionFailed: createAction<PaymentCompletionFailedPayload>('@@KL_EVT/PAYMENT_COMPLETION_FAILED'),
    bookingCreationCompleted: createAction<BookingCreationCompletedPayload>('@@KL_EVT/BOOKING_CREATION_COMPLETED'),
};

export default BookingCreationEvent;
