import React from 'react';
import {Box, Typography} from '@material-ui/core';
import moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {formatNumber} from '../../../i18n/i18n';

const useStyles = makeStyles(theme => ({
    orderItem: {
        padding: '0 15px',
    },
    infoPanel: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
    },
}));

interface RentalTimeProps {
    startDateTime: Date;
    endDateTime: Date|null;
    preparationTime: number;
}

const RentalTime = (props: RentalTimeProps) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const pickUpDateTime = moment(props.startDateTime).subtract(props.preparationTime, 'minutes');
    const duration = props.endDateTime
        ? moment.duration(moment(props.endDateTime).diff(moment(props.startDateTime))).asHours()
        : 0;

    return (
        <Box className={classes.infoPanel}>
            <Box className={classes.orderItem}>
                <Typography variant={'body2'} style={{ textTransform: 'uppercase' }}>
                    {t('general.date')}
                </Typography>
                <Typography>
                    {moment(props.startDateTime).format(t('formats.date'))}
                </Typography>
            </Box>
            <Box className={classes.orderItem}>
                <Box style={{textTransform: 'uppercase', textAlign: 'center'}}>
                    <Typography variant={'body2'}>{t('orders.start')}</Typography>
                    <Typography>{moment(props.startDateTime).format(t('formats.time'))}</Typography>
                </Box>
                {props.endDateTime && (
                    <Box style={{textTransform: 'uppercase', textAlign: 'center'}}>
                        <Typography variant={'body2'}>{t('orders.end')}</Typography>
                        <Typography>{moment(props.endDateTime).format(t('formats.time'))}</Typography>
                    </Box>
                )}
            </Box>
            <Box className={classes.orderItem}>
                <Typography style={{textTransform: 'uppercase'}} variant={'body2'}>
                    {t('orders.pick_up')}
                </Typography>
                <Typography>{pickUpDateTime.format(t('formats.time'))}</Typography>
                {props.endDateTime && (
                    <Box>
                        <Typography style={{textTransform: 'uppercase'}} variant={'body2'}>
                            {t('orders.rent_time')}
                        </Typography>
                        {formatNumber(duration)} {t('orders.hours_abbr')}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default RentalTime;
