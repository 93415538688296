import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AddNewOrderForm from '../orders/components/AddNewOrderForm';
import HintsDialog from './components/HintsDialog';
import {Box, Button} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import Command from '../../action/command';
import LoaderOverlay from '../layout/components/LoaderOverlay';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import Selector from '../../selector';
import {usePrevious} from '../../util/usePrevious';
import Routes from '../../routes';


const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
    },
    button: {
        margin: `${theme.spacing(4)}px auto 0 auto`,
    },
}));

interface AddNewOrderDialogProps {
    open: boolean;
}

const AddNewOrderDialog = (props: AddNewOrderDialogProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    const fetchingOrder = useSelector(Selector.Order.fetchingOrder);
    const fetchingOrderPrevious = usePrevious(fetchingOrder);
    const [orderNumber, setOrderNumber] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const addNewOrder = () => {
        setLoading(true);
        dispatch(Command.Order.fetchOrder({ internalOrderId: orderNumber, showFinishedWarning: true }));
    };

    useEffect(() => {
        if (fetchingOrderPrevious && !fetchingOrder && loading) {
            setLoading(false);
            history.push(Routes.ordersPath);
            setOrderNumber('');
        }
    }, [fetchingOrder]);

    return (
        <HintsDialog open={props.open}>
            <AddNewOrderForm value={orderNumber} onChange={setOrderNumber} />
            <Box className={classes.buttonWrapper}>
                <Button
                    className={classes.button}
                    variant={'contained'}
                    onClick={addNewOrder}
                    disabled={orderNumber.length < 10}
                    children={t('hints.fetch_ticket')}
                />
            </Box>
            <LoaderOverlay open={fetchingOrder} />
        </HintsDialog>
    );
};

export default AddNewOrderDialog;
