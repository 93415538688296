import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import Selector from '../../selector';
import LoaderOverlay from '../layout/components/LoaderOverlay';
import IndividualRentPasswordStep from './IndividualRentPage/IndividualRentPasswordStep';
import IndividualRentUsageStep from './IndividualRentPage/IndividualRentUsageStep';

interface IndividualRentPageProps {

}

const IndividualRentPage = (props: IndividualRentPageProps) => {
    const params = useParams<{ locationId: string, door: string }>();
    const locationId = Number(params.locationId);
    const door = Number(params.door);
    const location = useSelector(Selector.Location.makeLocation(locationId));
    const individualRental = useSelector(Selector.Order.individualRental);

    return (<>
        {!individualRental.data && (
            <IndividualRentPasswordStep
                locationId={locationId}
                door={door}
            />
        )}
        {individualRental.data && (
            <IndividualRentUsageStep
                individualRental={individualRental.data}
            />
        )}
        <LoaderOverlay open={!location} />
    </>);
};

export default IndividualRentPage;
