import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Logger from './util/logger';
import './i18n/i18n';
import * as Sentry from '@sentry/react';
import Config from './util/config';
import GTM from './util/gtm';

if (Config.environment !== 'development') {
    Sentry.init({
        dsn: 'https://8670b42d681f46c6b8f0c4594c87c70e@o445728.ingest.sentry.io/5424484',
        environment: Config.environment,
        release: Config.app.name + '@' + Config.app.version,
        beforeSend: (event) => {
            if (
                event.extra && typeof event.extra.request_data === 'string' &&
                (event.extra.request_data.includes('email') || event.extra.request_data.includes('telephone'))
            ) {
                delete event.extra.request_data;
            }

            return event;
        },
    });

    GTM.initialize();
}

ReactDOM.render(
    <App />,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(Logger.for('WebVitals').log);
