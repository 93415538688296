import {useLocation} from 'react-router-dom';
import {usePrevious} from '../../util/usePrevious';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Command from '../../action/command';
import GTM from '../../util/gtm';
import {persistLanguage} from '../../i18n/i18n';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

import 'moment/locale/de';
import 'moment/locale/nb';

/**
 * Monitors route changes and triggers certain actions when needed
 * Note: It might make more sense to manage the routes in the redux-store later (see react-router-redux)
 *
 * @constructor
 */
const RouteWatcher = () => {
    const { i18n } = useTranslation();
    const location = useLocation();
    const queryParamLanguage = new URLSearchParams(location.search).get('lng');
    const path = location.pathname;
    const previousPath = usePrevious(path);
    const dispatch = useDispatch();

    useEffect(
        () => {
            // Load orders if the initial page is not the orders page itself
            if (!path.includes('/orders')) {
                dispatch(Command.Order.loadOrders({}));
            }

            moment.locale(i18n.language);
        },
        [],
    );

    useEffect(
        () => {
            GTM.trackPageView(path, document.title);

            // Cancel reservation on leaving the booking process
            const wasInBookingProcess = Boolean(previousPath && previousPath.includes('/book'));
            const isCancelingBookingProcess = Boolean(!path.includes('/book') && !path.includes('/orders'));
            if (wasInBookingProcess && isCancelingBookingProcess) {
                dispatch(Command.BookingCreation.cancelBookingCreation({}));
            }
        },
        [path],
    );

    useEffect(
        () => {
            if (queryParamLanguage) {
                persistLanguage(queryParamLanguage);
            }
        },
        [queryParamLanguage],
    );

    return null;
};

export default RouteWatcher;
