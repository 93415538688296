import {call, fork, put, select, take} from 'redux-saga/effects';
import Command from '../action/command';
import Selector from '../selector';
import Logger from '../util/logger';
import Api from '../api';
import {onEnqueueErrorSnackbar} from './enqueueSnackbarFlow';
import {getI18n} from 'react-i18next';
import ErrorTracker from '../util/errorTracker';
import {Reservation} from '../types/bookingCreation';
import Event from '../action/event';
import {trackGTMRemoveFromCart} from './helpers/gtm';

export const onRemoveReservation = function*() {
    const reservation: null|Reservation = yield select(Selector.BookingCreation.reservation);

    if (reservation) {
        try {
            yield call(Api.removeReservation, reservation.reservationId);
        } catch (error) {
            Logger.for('Saga').error(error);
            ErrorTracker.trackException(error);
            yield fork(onEnqueueErrorSnackbar, getI18n().t('book_location.error.remove_reservation'));
        }
    }

    yield put(Event.BookingCreation.reservationRemoved({}));
    yield fork(trackGTMRemoveFromCart);
};

export function* removeReservationFlow() {
    while (true) {
        yield take(Command.BookingCreation.removeReservation.toString());
        yield fork(onRemoveReservation);
    }
}
