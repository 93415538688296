import React from 'react';
import Assets from '../../../assets/assets';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    logo: {
        width: '110px',
        [theme.breakpoints.up('lg')]: {
            width: '160px',
        },
    },
    center: {
        margin: '0 auto',
    },
}));

const KolulaSupLogoComponent = Assets.logo.kolulaSup.component;

interface KolulaSupLogoProps {
    center?: boolean;
}

const KolulaSupLogo = (props: KolulaSupLogoProps) => {
    const classes = useStyles();

    return (
        <Box className={props.center ? `${classes.logo} ${classes.center}` : classes.logo}>
            <KolulaSupLogoComponent />
        </Box>
    );
};

export default KolulaSupLogo;
