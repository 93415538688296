import {ReduxState} from '../reducer';

const stateKey = 'bookingCreation';

const BookingCreationSelector = {
    locationId: (state: ReduxState) => state[stateKey].locationId,
    step: (state: ReduxState) => state[stateKey].step,
    nextEnabled: (state: ReduxState) => state[stateKey].nextEnabled,
    isPartnerUsc: (state: ReduxState) => state[stateKey].partner === 'usc',
    selection: (state: ReduxState) => state[stateKey].selection,
    boardSelection: (state: ReduxState) => state[stateKey].boardSelection,
    reservation: (state: ReduxState) => state[stateKey].reservation,
    personalData: (state: ReduxState) => state[stateKey].personalData,
    personalDataValidation: (state: ReduxState) => state[stateKey].personalDataValidation,
    paymentProvider: (state: ReduxState) => state[stateKey].payment.provider,
    paymentType: (state: ReduxState) => state[stateKey].payment.paymentType,
    braintreePayment: (state: ReduxState) => state[stateKey].payment.braintree,
    vippsPayment: (state: ReduxState) => state[stateKey].payment.vipps,
    stripePayment: (state: ReduxState) => state[stateKey].payment.stripe,
    paymentInitFailed: (state: ReduxState) => state[stateKey].payment.paymentInitFailed,
    orderId: (state: ReduxState) => state[stateKey].payment.orderId,

    makeLoading: (key: 'couponRedemption'|'paymentCompletion') => (state: ReduxState) => state[stateKey].loading[key],
};

export default BookingCreationSelector;
