import TagManager, {DataLayerArgs} from 'react-gtm-module';
import Config from './config';
import {Location, Option} from '../types/location';
import {BookingCreationStep} from '../modules/bookLocation/BookLocationPage';
import {Reservation} from '../types/bookingCreation';
import Logger from './logger';
import ErrorTracker from './errorTracker';

// @see https://developers.google.com/tag-manager/enhanced-ecommerce

interface Product {
    location: Location;
    option: Option;
    quantity: number;
}

const gtmLogger = Logger.for('GTM');

const sendDataLayer = (dataLayerArgs: DataLayerArgs) => {
    try {
        TagManager.dataLayer(dataLayerArgs);

        if (Config.environment === 'development' || Config.environment === 'staging') {
            const eventName = dataLayerArgs.dataLayer && (dataLayerArgs.dataLayer as any).event
                ? (dataLayerArgs.dataLayer as any).event
                : 'NO_EVENT';

            gtmLogger.log(eventName, dataLayerArgs);
        }
    } catch (error) {
        ErrorTracker.trackException(error);
        gtmLogger.error(error);
    }
};

const GTM = {
    initialize: () => {
        TagManager.initialize({
            gtmId: Config.gtm.id,
            auth: Config.gtm.auth,
            preview: Config.gtm.preview,
        });
    },
    trackPageView: (route: string, title: string) => {
        sendDataLayer({
            dataLayer: {
                event: 'kolula:app:pageView',
                page: {
                    route,
                    title,
                },
            },
        });
    },
    trackEecImpressionsView: (locations: Location[]) => sendDataLayer({
        dataLayer: {
            event: 'kolula:app:eec:impressionsView',
            ecommerce: {
                currencyCode: 'EUR',
                impressions: locations.map(location => ({
                    id: String(location.id),
                    name: location.name,
                    list: 'Locations Map',
                })),
            },
        },
    }),
    // @todo fix not triggered on direct /locations/1 load
    trackEecProductClick: (location: Location) => sendDataLayer({
        dataLayer: {
            event: 'kolula:app:eec:productClick',
            ecommerce: {
                click: {
                    actionField: {
                        list: 'Locations Map',
                    },
                    products: [{
                        id: String(location.id),
                        name: location.name,
                    }],
                },
            },
        },
    }),
    trackEecProductDetails: (location: Location) => sendDataLayer({
        dataLayer: {
            event: 'kolula:app:eec:productDetails',
            ecommerce: {
                detail: {
                    actionField: {
                        list: 'Locations Map',
                    },
                    products: [{
                        id: String(location.id),
                        name: location.name,
                    }],
                },
            },
        },
    }),
    trackEecAddToCart: (product: Product) => sendDataLayer({
        dataLayer: {
            event: 'kolula:app:eec:addToCart',
            ecommerce: {
                currencyCode: 'EUR',
                add: {
                    products: [{
                        name: product.location.name,
                        id: product.location.id,
                        quantity: product.quantity,
                        variant: product.option.name,
                        price: product.option.price,
                    }],
                },
            },
        },
    }),
    trackFacebookAddToCart: (product: Product) => sendDataLayer({
        dataLayer: {
            event: 'kolula:app:facebook:addToCart',
            productData: {
                name: product.location.name,
                id: product.location.id,
            },
        },
    }),
    trackEecRemoveFromCart: (product: Product) => sendDataLayer({
        dataLayer: {
            event: 'kolula:app:eec:removeFromCart',
            ecommerce: {
                remove: {
                    products: [{
                        name: product.location.name,
                        id: product.location.id,
                        quantity: product.quantity,
                        variant: product.option.name,
                        price: product.option.price,
                    }],
                },
            },
        },
    }),
    trackEecCheckoutStep: (step: BookingCreationStep) => sendDataLayer({
        dataLayer: {
            event: 'kolula:app:eec:checkoutStep',
            ecommerce: {
                checkout: {
                    actionField: {
                        step: step + 1,
                        list: 'Locations Map',
                    },
                },
            },
        },
    }),
    trackEecPlaceOrder: (internalOrderId: string, reservation: Reservation, products: Product[]) => sendDataLayer({
        dataLayer: {
            event: 'kolula:app:eec:placeOrder',
            ecommerce: {
                purchase: {
                    actionField: {
                        id: internalOrderId,
                        affiliation: 'Kolula App',
                        revenue: reservation.payment.amount,
                        tax: reservation.payment.tax,
                        coupon: reservation.discount ? reservation.discount.code : '',
                        list: 'Locations Map',
                    },
                    products: products.map(product => ({
                        name: product.location.name,
                        id: product.location.id,
                        quantity: product.quantity,
                        variant: product.option.name,
                        price: product.option.price,
                    })),
                },
            },
        },
    }),
};

export default GTM;
