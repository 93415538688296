import {call, fork, put, take} from 'redux-saga/effects';
import Command from '../action/command';
import {Action} from 'redux-actions';
import Api from '../api';
import Logger from '../util/logger';
import {onEnqueueErrorSnackbar} from './enqueueSnackbarFlow';
import {getI18n} from 'react-i18next';
import {VerifyIndividualRentalPayload} from '../action/orderCommand';
import ErrorTracker from '../util/errorTracker';
import {IndividualRental} from '../types/individualRental';
import Event from '../action/event';

export const onVerifyIndividualOrder = function*(locationId: number, door: number, password: string) {
    try {
        yield put(Event.Order.individualRentalVerificationBegan({}));

        const individualRental: IndividualRental = yield call(
            Api.verifyIndividualRental,
            locationId,
            door,
            password,
        );

        yield put(Event.Order.individualRentalVerificationCompleted({ individualRental }));
    } catch (error) {
        yield call(handleVerifyIndividualOrderError, error);
    }
};

const handleVerifyIndividualOrderError = function*(error: any) {
    Logger.for('Saga').error(error);
    ErrorTracker.trackException(error);
    yield put(Event.Order.individualRentalVerificationFailed({}));

    if (
        error.response &&
        error.response.status === 400 &&
        error.response.data?.msg &&
        error.response.data.msg.includes('expire')
    ) {
        yield fork(onEnqueueErrorSnackbar, getI18n().t('individual_rent.error.rental_expired'));
    } else {
        yield fork(onEnqueueErrorSnackbar, getI18n().t('individual_rent.error.password_verification_failed'));
    }
};

export function* verifyIndividualRentalFlow() {
    while (true) {
        const action: Action<VerifyIndividualRentalPayload> = yield take(
            Command.Order.verifyIndividualRental.toString(),
        );

        yield fork(onVerifyIndividualOrder, action.payload.locationId, action.payload.door, action.payload.password);
    }
}
