import React from 'react';
import {IndividualRental} from '../../../types/individualRental';
import Page from '../../layout/components/Page';
import Header from '../../layout/components/Header';
import {useTranslation} from 'react-i18next';
import Footer from '../../layout/components/Footer';
import {Box, Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import Selector from '../../../selector';
import moment from 'moment';
import KolulaBox from '../../useOrder/box/KolulaBox';
import Event from '../../../action/event';
import Command from '../../../action/command';

const useStyles = makeStyles(theme => ({
    rentalInfoBox: {
        color: 'white',
        marginBottom: theme.spacing(1.5),
        textAlign: 'center',
    },
    doorButton: {
        border: 'none !important',
        borderRadius: 4,
        display: 'block',
        margin: '0 auto',
    },
}));

interface IndividualRentUsageStepProps {
    individualRental: IndividualRental;
}

const IndividualRentUsageStep = (props: IndividualRentUsageStepProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const location = useSelector(Selector.Location.makeLocation(props.individualRental.locationId));

    const openDoor = () => {
        dispatch(Command.Order.openIndividualRentalDoor({}));
    };

    const completeIndividualRental = () => {
        /* Delay only to make it feel a little better in the UI */
        setTimeout(
            () => dispatch(Event.Order.individualRentalCompleted({})),
            100,
        );
    };

    return (
        <Page
            header={<Header text={location?.name + ' — ' + t('individual_rent.header.open_door')} />}
            children={(<>
                <Box className={classes.rentalInfoBox}>
                    Gebucht bis: {moment(props.individualRental.validUntil).format(t('formats.date'))}
                </Box>
                <KolulaBox activeDoors={[props.individualRental.door]} />
                <Box style={{ marginTop: 16 }}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        size={'small'}
                        className={classes.doorButton}
                        children={t('individual_rent.open_door')}
                        onClick={openDoor}
                    />
                </Box>
            </>)}
            footer={(
                <Footer
                    children={(
                        <Button
                            variant={'contained'}
                            children={t('individual_rent.back_to_password_entry')}
                            onClick={completeIndividualRental}
                        />
                    )}
                />
            )}
        />
    );
};

export default IndividualRentUsageStep;
