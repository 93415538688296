import React from 'react';
import {BottomNavigation, BottomNavigationAction} from '@material-ui/core';
import {Link} from 'react-router-dom';
import Routes from '../../../routes';
import {makeStyles} from '@material-ui/core/styles';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import InfoIcon from '@material-ui/icons/Info';
import MapIcon from '@material-ui/icons/Map';
import {useTranslation} from 'react-i18next';
import {useRouteMatch} from 'react-router';

const useStyles = makeStyles(theme => ({
    root: {
        height: '59px',
        backgroundColor: 'var(--toolbar-background-color)',
    },
    actionRoot: {
        color: 'white',
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
        '&:not(:first-child)': {
            borderLeft: '1px solid #9F9F9F',
        },
    },
    actionSvgIcon: {
        fontSize: '30px',
    },
}));

const Toolbar = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const locationsPathActive = Boolean(useRouteMatch({ path: Routes.locationsPath }));
    const ordersPathActive = Boolean(useRouteMatch({ path: Routes.ordersPath }));
    const vippsReturnPathActive = Boolean(useRouteMatch({ path: Routes.vippsPaymentReturnPath }));
    const stripeReturnPathActive = Boolean(useRouteMatch({ path: Routes.stripePaymentReturnPath }));
    const paymentsPathActive = Boolean(vippsReturnPathActive || stripeReturnPathActive);

    const locationsActive = Boolean(locationsPathActive);
    const ordersActive = Boolean(ordersPathActive || paymentsPathActive);

    const value = locationsActive ? 0 : ordersActive ? 1 : 2;

    return (
        <BottomNavigation value={value} id={'toolbar'} className={classes.root} showLabels={true}>
            <BottomNavigationAction
                label={t('toolbar.locations')}
                icon={<MapIcon className={classes.actionSvgIcon} />}
                to={Routes.locationsPath}
                component={Link}
                classes={{
                    root: classes.actionRoot,
                }}
            />
            <BottomNavigationAction
                label={t('toolbar.bookings')}
                icon={<ConfirmationNumberIcon className={classes.actionSvgIcon} />}
                to={Routes.ordersPath}
                component={Link}
                classes={{
                    root: classes.actionRoot,
                }}
            />
            <BottomNavigationAction
                label={t('toolbar.info')}
                icon={<InfoIcon className={classes.actionSvgIcon} />}
                to={Routes.hintsPath}
                component={Link}
                classes={{
                    root: classes.actionRoot,
                }}
            />
        </BottomNavigation>
    );
};

export default Toolbar;
