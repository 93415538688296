import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    rectangle: {
        backgroundColor: theme.palette.primary.main,
        width: '61px',
        height: '30px',
        position: 'absolute',
    },
}));

interface KolulaBoxRectangleProps {
    posX: number;
    posY: number;
}

const KolulaBoxRectangle = (props: KolulaBoxRectangleProps) => {
    const classes = useStyles();

    return (
        <Box
            className={classes.rectangle}
            style={{
                left: props.posX,
                top: props.posY,
            }}
        />
    );
};

export default KolulaBoxRectangle;
