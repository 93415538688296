import React, {Suspense, useEffect} from 'react';
import {theme} from './material-ui/theme';
import {ThemeProvider} from '@material-ui/styles';
import {BrowserRouter, Redirect} from 'react-router-dom';
import {Provider, useDispatch} from 'react-redux';
import {store} from './store';
import {Route, Switch, useHistory, useRouteMatch} from 'react-router';
import Routes from './routes';
import LocationsPage from './modules/locations/LocationsPage';
import MainLayout from './modules/layout/components/MainLayout';
import Notifier from './modules/common/Notifier';
import OrdersPage from './modules/orders/OrdersPage';
import LoaderOverlay from './modules/layout/components/LoaderOverlay';
import RouteWatcher from './modules/common/RouteWatcher';
import AddNewOrderDialog from './modules/hints/AddNewOrderDialog';
import OrderLinkHandler from './modules/common/OrderLinkHandler';
import PriceDialog from './modules/hints/PriceDialog';
import FAQsDialog from './modules/hints/FAQsDialog';
import HintsPage from './modules/hints/HintsPage';
import IndividualRentPage from './modules/individualRent/IndividualRentPage';
import Command from './action/command';
import Config from './util/config';
import {loadStripeCheckout} from './util/stripe';
import {loadVippsCheckoutSDK} from './util/vipps';
import VippsPaymentReturnPage from './modules/payments/VippsPaymentReturnPage';
import StripePaymentReturnPage from './modules/payments/StripePaymentReturnPage';

/* eslint-disable max-len */
const BookLocationDialog = React.lazy(() => import('./modules/bookLocation/BookLocationDialog' /* webpackChunkName: "book-location-dialog" */));
const UseOrderDialog = React.lazy(() => import('./modules/useOrder/UseOrderDialog' /* webpackChunkName: "use-order-dialog" */));
const ReportDamageDialog = React.lazy(() => import('./modules/reportDamage/ReportDamageDialog' /* webpackChunkName: "report-damage-dialog" */));
const ImprintDialog = React.lazy(() => import('./modules/hints/ImprintDialog' /* webpackChunkName: "imprint-dialog" */));
const DataProtectionDialog = React.lazy(() => import('./modules/hints/DataProtectionDialog' /* webpackChunkName: "data-protection-dialog" */));
const TermsOfServiceDialog = React.lazy(() => import('./modules/hints/TermsOfServiceDialog' /* webpackChunkName: "terms-of-service-dialog" */));
const TipsDialog = React.lazy(() => import('./modules/hints/TipsDialog' /* webpackChunkName: "tips-dialog" */));

const Main = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const locationRouteMatch = useRouteMatch<{ locationId: string }>({ path: [Routes.locationPath, Routes.bookLocationPath], exact: true });
    const orderRouteMatch = useRouteMatch<{ orderId: string }>({ path: [Routes.useOrderPath, Routes.reportDamagePath, Routes.orderFaqsPath], exact: true });

    const locationId = locationRouteMatch ? Number(locationRouteMatch.params.locationId!) : undefined;
    const orderId = orderRouteMatch ? orderRouteMatch.params.orderId! : undefined;

    const showBookLocationDialog = Boolean(locationRouteMatch && locationRouteMatch.path === Routes.bookLocationPath);
    const showUseOrderDialog = Boolean(orderRouteMatch);
    const showReportDamageDialog = Boolean(useRouteMatch({ path: Routes.reportDamagePath }));
    const showOrderFAQsDialog = Boolean(useRouteMatch({ path: Routes.orderFaqsPath }));
    const showImprintDialog = Boolean(useRouteMatch({ path: Routes.imprintPath }));
    const showDataProtectionDialog = Boolean(useRouteMatch({ path: Routes.dataProtectionPath }));
    const showTermsOfServiceDialog = Boolean(useRouteMatch({ path: Routes.termsOfServicePath }));
    const showTipsDialog = Boolean(useRouteMatch({ path: Routes.tipsPath }));
    const showAddNewOrderDialog = Boolean(useRouteMatch({ path: Routes.addOrderPath }));
    const showPriceDialog = Boolean(useRouteMatch({ path: Routes.pricePath }));
    const showFAQsDialog = Boolean(useRouteMatch({ path: Routes.faqsPath }));
    const showAnyHintsDialog = showImprintDialog || showDataProtectionDialog || showTermsOfServiceDialog || showTipsDialog || showAddNewOrderDialog || showPriceDialog || showFAQsDialog;

    useEffect(() => {
        dispatch(Command.Location.fetchLocations({}));

        // vipps support
        if (Config.tenantConfig.paymentProvider.includes('vipps')) {
            loadVippsCheckoutSDK();
        }
        // stripe support
        if (Config.tenantConfig.paymentProvider.includes('stripe')) {
            loadStripeCheckout();
        }
    }, []);

    return (<>
        <Switch>
            <Route path={Routes.locationsPath} exact={true} component={LocationsPage} />
            <Route path={Routes.locationPath} component={LocationsPage} />
            <Route path={Routes.ordersPath} exact={true} component={OrdersPage} />
            <Route path={Routes.useOrderPath} component={OrdersPage} />
            <Route path={Routes.vippsPaymentReturnPath} exact={true} component={VippsPaymentReturnPage} />
            <Route path={Routes.stripePaymentReturnPath} exact={true} component={StripePaymentReturnPage} />
            <Route path={Routes.hintsPath} component={HintsPage} />
            <Route path={Routes.individualRent} component={IndividualRentPage} />

            <Route path={'/bookings/:orderId'} component={OrderLinkHandler} />
            <Redirect from={'/'} to={Routes.locationsPath} />
        </Switch>

        <Suspense fallback={showBookLocationDialog ? <LoaderOverlay open={true} /> : null}>
            <BookLocationDialog open={showBookLocationDialog} locationId={locationId} />
        </Suspense>
        <Suspense fallback={showUseOrderDialog ? <LoaderOverlay open={true} /> : null}>
            <UseOrderDialog open={showUseOrderDialog} orderId={orderId} />
            <ReportDamageDialog open={showReportDamageDialog} orderId={orderId} />
            <FAQsDialog open={showOrderFAQsDialog} onClose={() => history.goBack()} />
        </Suspense>
        <Suspense fallback={showAnyHintsDialog ? <LoaderOverlay open={true} /> : null}>
            <ImprintDialog open={showImprintDialog} />
            <DataProtectionDialog open={showDataProtectionDialog} />
            <TermsOfServiceDialog open={showTermsOfServiceDialog} />
            <TipsDialog open={showTipsDialog} />
            <AddNewOrderDialog open={showAddNewOrderDialog} />
            <PriceDialog open={showPriceDialog} />
            <FAQsDialog open={showFAQsDialog} />
        </Suspense>
    </>);
};

const App = () => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <Notifier />
                <BrowserRouter>
                    <RouteWatcher />
                    <MainLayout>
                        <Main />
                    </MainLayout>
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    );
};

export default App;
