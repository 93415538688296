import Page from '../../layout/components/Page';
import Header from '../../layout/components/Header';
import {InputAdornment, TextField, Typography} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Footer from '../../layout/components/Footer';
import LoaderButton from '../../layout/components/LoaderButton';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Selector from '../../../selector';
import {makeStyles} from '@material-ui/core/styles';
import Command from '../../../action/command';

const isValidPassword = (password: string): boolean => /^[A-Za-z0-9!.,]{8,}$/.test(password);

const useStyles = makeStyles(theme => ({
    enterPasswordDescription: {
        color: 'white',
        textAlign: 'center',
    },
    fastPageAccessInfo: {
        color: 'white',
        textAlign: 'center',
        marginTop: theme.spacing(4),
    },
    input: {
        marginTop: theme.spacing(4),
        '& .MuiFormLabel-root': {
            color: 'white',
        },
        '& .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
        },
        '& .MuiInputBase-input': {
            color: 'white',
        },
    },
}));

interface IndividualRentPasswordStepProps {
    locationId: number;
    door: number;
}

const IndividualRentPasswordStep = (props: IndividualRentPasswordStepProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const location = useSelector(Selector.Location.makeLocation(props.locationId));
    const [password, setPassword] = useState<string>('');
    const individualRental = useSelector(Selector.Order.individualRental);

    const checkPassword = () => {
        dispatch(Command.Order.verifyIndividualRental({
            locationId: props.locationId,
            door: props.door,
            password,
        }));
    };

    return (
        <Page
            header={<Header text={t('individual_rent.header.enter_password')} />}
            children={!location ? null : (<>
                <Typography variant={'body1'} className={classes.enterPasswordDescription}>
                    <Trans
                        i18nKey={'individual_rent.enter_password_description'}
                        values={{ door: props.door, locationName: location.name }}
                        components={{ strong: <strong /> } as any}
                    />
                </Typography>
                <TextField
                    className={classes.input}
                    fullWidth={true}
                    label={t('individual_rent.password')}
                    variant={'outlined'}
                    color={'primary'}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    autoComplete={'current-password'}
                    inputProps={{
                        type: 'password',
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position={'start'}>
                                <VpnKeyIcon style={{ color: 'white' }} />
                            </InputAdornment>
                        ),
                    }}
                />
                <Typography variant={'body2'} className={classes.fastPageAccessInfo}>
                    {t('individual_rent.fast_page_access_info')}
                </Typography>
            </>)}
            footer={(
                <Footer
                    children={(<>
                        <LoaderButton
                            onClick={checkPassword}
                            variant={'contained'}
                            color={'secondary'}
                            children={t('individual_rent.check_password')}
                            disabled={!isValidPassword(password)}
                            loading={individualRental.loading}
                        />
                    </>)}
                />
            )}
        />
    );
};

export default IndividualRentPasswordStep;
