
type Environment = 'development'|'staging'|'production';
type Tenant = 'kolula'|'solheimsviken';
type PaymentProvider = 'braintree'|'vipps'|'stripe';

interface TenantConfig {
    languages: string[];
    taxRate: number;
    currency: string;
    paymentProvider: string[];
    map: {
        center: [number, number];
        zoom: number;
    };
    phoneInput: {
        defaultCountry: string;
        preferredCountries: string[];
    };
    prices: Array<{
        duration: number;
        weekdayPrice: number;
        weekendPrice: number;
        bestseller?: boolean;
    }>;
    contact: {
        phone: string;
        phoneFormatted: string;
    };
}

interface ConfigType {
    environment: Environment;
    tenant: Tenant;
    app: {
        version: string;
        name: string;
    };
    mapBox: {
        apiKey: string;
    };
    api: {
        baseUrl: string;
        key: string;
    };
    gtm: {
        id: string;
        auth: string;
        preview: string;
    };
    tenantConfig: TenantConfig;
}

const tenant: Tenant = process.env.REACT_APP_TENANT as Tenant;
// const paymentProvider: PaymentProvider = process.env.REACT_APP_PAYMENT_PROVIDER as PaymentProvider;
const paymentProvider: PaymentProvider[] = process.env.REACT_APP_PAYMENT_PROVIDER
    ? process.env.REACT_APP_PAYMENT_PROVIDER.split(',') as PaymentProvider[]
    : [];

const Config: ConfigType = {
    environment: process.env.REACT_APP_ENV as Environment,
    tenant,
    app: {
        version: process.env.REACT_APP_VERSION!,
        name: process.env.REACT_APP_NAME!,
    },
    mapBox: {
        apiKey: process.env.REACT_APP_MAPBOX_API_KEY!,
    },
    api: {
        baseUrl: process.env.REACT_APP_API_BASE_URL!,
        key: process.env.REACT_APP_API_KEY!,
    },
    gtm: {
        id: process.env.REACT_APP_GTM_ID!,
        auth: process.env.REACT_APP_GTM_AUTH!,
        preview: process.env.REACT_APP_GTM_PREVIEW!,
    },
    tenantConfig: ({
        kolula: {
            languages: ['en', 'de'],
            taxRate: 19,
            currency: 'EUR',
            paymentProvider: paymentProvider,
            map: {
                center: [10.3935449, 50.5763016] as [number, number],
                zoom: 4.8,
            },
            phoneInput: {
                defaultCountry: 'de',
                preferredCountries: ['de', 'at', 'ch', 'nl', 'be'],
            },
            prices: [
                { duration: 1, weekdayPrice: 9.99, weekendPrice: 12.99 },
                { duration: 1.5, weekdayPrice: 13.99, weekendPrice: 17.99, bestseller: true },
                { duration: 2, weekdayPrice: 17.99, weekendPrice: 23.99 },
                { duration: 3, weekdayPrice: 24.99, weekendPrice: 29.99 },
                { duration: 4, weekdayPrice: 29.99, weekendPrice: 36.99 },
            ],
            contact: {
                phone: '+4989244183790',
                phoneFormatted: '089 244 183 790',
            },
        },
        solheimsviken: {
            languages: ['en', 'nb'],
            taxRate: 25,
            currency: 'NOK',
            paymentProvider: paymentProvider,
            map: {
                center: [8.548814, 61.576894] as [number, number],
                zoom: 4.6,
            },
            phoneInput: {
                defaultCountry: 'no',
                preferredCountries: ['no'],
            },
            prices: [
                { duration: 1, weekdayPrice: 200, weekendPrice: 200 },
                { duration: 2, weekdayPrice: 300, weekendPrice: 300 },
                { duration: 3, weekdayPrice: 400, weekendPrice: 400 },
                { duration: 4, weekdayPrice: 500, weekendPrice: 500 },
                { duration: 8, weekdayPrice: 700, weekendPrice: 700 },
            ],
            contact: {
                phone: '+4796902269',
                phoneFormatted: '+47 969 02 269',
            },
        },
    })[tenant],
};

export default Config;
