import {call, fork, put, select, take} from 'redux-saga/effects';
import Command from '../action/command';
import Event from '../action/event';
import Selector from '../selector';
import Logger from '../util/logger';
import Api from '../api';
import {onEnqueueErrorSnackbar} from './enqueueSnackbarFlow';
import {getI18n} from 'react-i18next';
import {BookingCreationSelection} from '../reducer/bookingCreationReducer';
import ErrorTracker from '../util/errorTracker';
import {trackGTMAddToCart} from './helpers/gtm';
import {Reservation} from '../types/bookingCreation';

export const onCreateNewReservation = function*() {
    const locationId: number|null = yield select(Selector.BookingCreation.locationId);

    if (!locationId) {
        return;
    }

    const selection: BookingCreationSelection = yield select(Selector.BookingCreation.selection);
    const boardSelection: Record<string, number> = yield select(Selector.BookingCreation.boardSelection);

    try {
        const reservation: Reservation = yield call(Api.createNewReservation, {
            locationId,
            dateTime: selection.date!.format('YYYY-MM-DD') + ' ' + selection.time,
            items: Object.keys(boardSelection).map(optionId => ({
                optionId,
                quantity: boardSelection[optionId],
            })),
        });

        yield put(Event.BookingCreation.reservationCreated({ reservation }));
    } catch (error) {
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);
        yield fork(onEnqueueErrorSnackbar, getI18n().t('book_location.error.create_reservation'));
    }

    yield fork(trackGTMAddToCart);
};

export function* createNewReservationFlow() {
    while (true) {
        yield take(
            Command.BookingCreation.createNewReservation.toString(),
        );

        yield fork(onCreateNewReservation);
    }
}
