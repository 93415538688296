import {call, put, select} from 'redux-saga/effects';
import Event from '../action/event';
import KolulaStorageManager from '../storage/KolulaStorageManager';
import Selector from '../selector';
import {PersonalData} from '../types/bookingCreation';
import ErrorTracker from '../util/errorTracker';
import Logger from '../util/logger';
import {IDBPersonalData} from '../storage/types';

export const onLoadDefaultPersonalData = function*() {
    try {
        const kolulaStorageManager = new KolulaStorageManager();
        const personalData: IDBPersonalData|null = yield call(kolulaStorageManager.getPersonalData);

        if (null === personalData) {
            return;
        }

        yield put(Event.BookingCreation.personalDataChanged({
            firstName: personalData.firstName,
            lastName: personalData.lastName,
            phone: personalData.phone,
            email: personalData.email,
            newsletterConsent: personalData.newsletterConsent,
            uscCustomerId: personalData.uscCustomerId || undefined,
        }));
    } catch (error) {
        ErrorTracker.trackException(error);
        Logger.error(error);
    }
};

export const onPersistDefaultPersonalData = function*() {
    try {
        const kolulaStorageManager = new KolulaStorageManager();

        const personalData: PersonalData = yield select(Selector.BookingCreation.personalData);
        yield call(kolulaStorageManager.setPersonalData, {...personalData, id: 'personal_data'});
    } catch (error) {
        ErrorTracker.trackException(error);
        Logger.error(error);
    }
};
