import {call, fork, put, select, take} from 'redux-saga/effects';
import Command from '../action/command';
import Logger from '../util/logger';
import {Action} from 'redux-actions';
import {LocationAvailability} from '../types/location';
import Api from '../api';
import Event from '../action/event';
import {FetchAvailabilitiesPayload} from '../action/locationCommand';
import {onEnqueueErrorSnackbar, onEnqueueInfoSnackbar} from './enqueueSnackbarFlow';
import {getI18n} from 'react-i18next';
import ErrorTracker from '../util/errorTracker';
import {
    getAvailableDurations,
    getAvailableTimeSlots,
    isDateAvailable,
} from '../modules/bookLocation/util/selectionStepHelpers';
import moment from 'moment';
import Selector from '../selector';

const onAutoSelectNextAvailableSlot = function*(locationId: number, locationAvailability: LocationAvailability) {
    try {
        const isPartnerUsc: boolean = yield select(Selector.BookingCreation.isPartnerUsc);

        const today = moment();
        const isTodayAvailable = isDateAvailable(locationAvailability, today, isPartnerUsc);

        if (!isTodayAvailable) {
            yield fork(onEnqueueInfoSnackbar, getI18n().t('book_location.selection.no_slot_available_today'));
            return;
        }

        const timeSlots = getAvailableTimeSlots(locationAvailability, today);
        if (timeSlots.length < 1) {
            return;
        }

        const nextAvailableTime = timeSlots[0];
        const durations = getAvailableDurations(locationAvailability, today, nextAvailableTime);
        if (durations.length < 1) {
            return;
        }

        const duration = durations.includes(1.5) ? 1.5 : durations[0];

        yield put(Event.BookingCreation.dateSelected({ date: today }));
        yield put(Event.BookingCreation.timeSelected({ time: nextAvailableTime }));
        yield put(Event.BookingCreation.durationSelected({ duration: duration }));
    } catch (error) {
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);
    }
};

export const onFetchAvailabilities = function*(locationId: number, autoSelectNextSlot: boolean) {
    try {
        const locationAvailability: LocationAvailability = yield call(Api.fetchAvailabilities, locationId);
        yield put(Event.Location.availabilitiesFetched({ locationId, locationAvailability }));

        if (autoSelectNextSlot) {
            yield call(onAutoSelectNextAvailableSlot, locationId, locationAvailability);
        }
    } catch (error) {
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);
        yield fork(onEnqueueErrorSnackbar, getI18n().t('book_location.error.fetch_availabilities'));
    }
};

export function* fetchAvailabilitiesFlow() {
    while (true) {
        const action: Action<FetchAvailabilitiesPayload> = yield take(Command.Location.fetchAvailabilities.toString());

        yield fork(onFetchAvailabilities, action.payload.locationId, Boolean(action.payload.autoSelectNextSlot));
    }
}
