import {call, fork, put, select, take} from 'redux-saga/effects';
import Command from '../action/command';
import {Action} from 'redux-actions';
import Api from '../api';
import Logger from '../util/logger';
import {onEnqueueErrorSnackbar} from './enqueueSnackbarFlow';
import {getI18n} from 'react-i18next';
import {StartOrderPayload} from '../action/orderCommand';
import Event from '../action/event';
import Selector from '../selector';
import {Order, Rental} from '../types/order';
import ErrorTracker from '../util/errorTracker';
import {setOrderStartedFlag} from './helpers/startedOrdersStorage';
import moment from 'moment';
import HttpStatusCodes from '../util/httpStatusCodes';
import {Location} from '../types/location';

const showAttemptToStartTooEarlyInfo = (order: Order, currentLocationTime: Date, preparationTime: number) => {
    const startDate = moment(order.startDateTime).startOf('day');
    const locationDate = moment(currentLocationTime).startOf('day');
    const isMoreThanOneDayToStart = startDate > locationDate;

    if (isMoreThanOneDayToStart) {
        const startDateTimeMoment = moment(order.startDateTime);
        alert(getI18n().t(
            'use_order.start_days_before_rental_explanation',
            {
                date: startDateTimeMoment.format(getI18n().t('formats.date')),
                time: startDateTimeMoment.format(getI18n().t('formats.time')),
                preparationTime,
            },
        ));
    } else {
        alert(getI18n().t('use_order.start_before_rental_explanation', { preparationTime }));
    }
};

const startOrder = function*(order: Order, resourcesCount?: number) {
    yield put(Event.Order.orderStartingBegan({}));

    const orderIdParts = order.id.split('_');
    const internalOrderId = orderIdParts.shift()!;

    const rental: Rental = yield call(
        Api.startOrder,
        internalOrderId,
        orderIdParts,
        resourcesCount,
    );

    yield put(Event.Order.orderStarted({ rental, startedTimestamp: rental.startedAt || new Date() }));
    yield fork(setOrderStartedFlag, order.id);
};

const handleStartOrderError = function*(error: any) {
    Logger.for('Saga').error(error);
    ErrorTracker.trackException(error);
    yield fork(onEnqueueErrorSnackbar, getI18n().t('use_order.error.start_order'));
    yield put(Event.Order.orderStartingFailed({}));
};

const showTooFewResourcesAvailableInfo = function*(availableCount: number, bookedCount: number, order: Order) {
    const confirmationResult = window.confirm(getI18n().t(
        'use_order.too_few_resources_available_explanation',
        {
            availableCount,
            bookedCount,
        },
    ));

    if (confirmationResult) {
        try {
            yield call(startOrder, order, availableCount);
        } catch (error) {
            yield call(handleStartOrderError, error);
        }
    } else {
        yield put(Event.Order.orderStartingFailed({}));
    }
};

const handleStartOrderErrorWithPossibleRetry = function*(error: any, order: Order) {
    const tooFewResourcesAvailable = error?.response?.status === HttpStatusCodes.PreconditionFailed;

    if (tooFewResourcesAvailable) {
        if (error?.response?.data?.available < 1) {
            alert(getI18n().t('use_order.no_resources_available_explanation'));
            yield put(Event.Order.orderStartingFailed({}));
            return;
        }

        yield call(
            showTooFewResourcesAvailableInfo,
            error?.response?.data?.available || 0,
            error?.response?.data?.totalNeeded || 0,
            order,
        );
        return;
    }

    yield call(handleStartOrderError, error);
};

const onStartOrder = function*(orderId: string) {
    const order: Order|null = yield select(Selector.Order.makeOrder(orderId));
    const location: Location|null = yield select(Selector.Location.makeLocation(order?.locationId || 0));

    if (!order || !location) {
        return;
    }

    const currentLocationTime = new Date(new Date().getTime() + order.locationTimeOffset);
    const minutesToStart = (order.startDateTime.getTime() - currentLocationTime.getTime()) / 60000;
    const minutesToEnd = (order.endDateTime!.getTime() - currentLocationTime.getTime()) / 60000;

    // @todo this should trigger navigation back to order list

    if (minutesToStart > location.preparationTime) {
        showAttemptToStartTooEarlyInfo(order, currentLocationTime, location.preparationTime);
        return;
    }

    if (minutesToEnd < -location.followUpTime) {
        alert(getI18n().t('use_order.start_after_rental_explanation', { followUpTime: location.followUpTime }));
        return;
    }

    try {
        yield call(startOrder, order);
    } catch (error) {
        yield call(handleStartOrderErrorWithPossibleRetry, error, order);
    }
};

export function* startOrderFlow() {
    while (true) {
        const action: Action<StartOrderPayload> = yield take(Command.Order.startOrder.toString());

        yield fork(onStartOrder, action.payload.orderId);
    }
}
