import {Box, Button, Typography} from '@material-ui/core';
import Routes from '../../../routes';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import InfoBox from '../../layout/components/InfoBox';
import React from 'react';
import {Location} from '../../../types/location';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

const useStyles = makeStyles(theme => ({
    header: {
        width: 'calc(100% - 40px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center',
        color: theme.palette.primary.contrastText,
    },
    text: {
        marginTop: theme.spacing(1),
        color: theme.palette.primary.contrastText,
    },
    bookButton: {
        margin: `${theme.spacing(1)}px 0`,
    },
    safeAreaSpacer: {
        height: 'env(safe-area-inset-bottom, 0)',
    },
}));

interface LocationDetailsProps {
    location: Location;
}

const LocationDetails = (props: LocationDetailsProps) => {
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation();

    return (<>
        <Typography variant={'h6'} className={classes.header}>{props.location.name}</Typography>
        <Button
            children={t('general.book')}
            variant={'contained'}
            disableElevation={true}
            fullWidth={true}
            disabled={!props.location.status.bookable}
            onClick={() => history.push(Routes.makeBookLocationUrl(props.location!.id))}
            className={classes.bookButton}
        />
        <Button
            children={'Google'}
            startIcon={<PlaceOutlinedIcon />}
            variant={'text'}
            component={'a'}
            href={`https://www.google.com/maps/search/?api=1&query=${props.location.gmapsQuery}`}
            target={'_blank'}
            rel="noopener noreferrer"
        />
        {props.location.status.notification && (
            <Box marginTop={1}>
                <InfoBox
                    variant={props.location.status.notification.type}
                    text={props.location.status.notification.text}
                />
            </Box>
        )}
        <Typography
            className={classes.text}
            dangerouslySetInnerHTML={{ __html: props.location.description }}
        />
        <Box className={classes.safeAreaSpacer} />
    </>);
};

export default LocationDetails;
