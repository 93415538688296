import LocationEvent from './locationEvent';
import BookingCreationEvent from './bookingCreationEvent';
import NotificationEvent from './notificationEvent';
import OrderEvent from './orderEvent';

const Event = {
    Location: LocationEvent,
    BookingCreation: BookingCreationEvent,
    Notification: NotificationEvent,
    Order: OrderEvent,
};

export default Event;
