import kolulaLogo, {ReactComponent as KolulaLogo} from './logos/kolula.svg';
import kolulaSupLogo, {ReactComponent as KolulaSupLogo} from './logos/kolula-sup.svg';
import kolulaWhiteLogo, {ReactComponent as KolulaWhiteLogo} from './logos/kolula-white.svg';
import mapKolulaPinOrangeIcon from './icons/map/kolula-pin-orange.png';
import mapKolulaPinBlueIcon from './icons/map/kolula-pin-blue.png';
import mapKolulaPinGrayIcon from './icons/map/kolula-pin-gray.png';
import mapDbPinOrangeIcon from './icons/map/db-pin-orange.png';
import mapDbPinBeigeIcon from './icons/map/db-pin-beige.png';
import mapDbPinGrayIcon from './icons/map/db-pin-gray.png';
import flagGermany from './flags/germany.svg';
import flagUnitedKingdom from './flags/united-kingdom.svg';
import flagNorway from './flags/norway.svg';
import uscScanQrCode, {ReactComponent as UscScanQrCode} from './icons/usc/scan-qr-code.svg';
import udTransferMoney from './unDraw/undraw-transfer-money.svg';
import udWarning from './unDraw/undraw-warning.svg';

const Assets = {
    flags: {
        germany: flagGermany,
        unitedKingdom: flagUnitedKingdom,
        norway: flagNorway,
    },
    icon: {
        map: {
            kolulaPinOrange: {
                url: mapKolulaPinOrangeIcon,
            },
            kolulaPinBlue: {
                url: mapKolulaPinBlueIcon,
            },
            kolulaPinGray: {
                url: mapKolulaPinGrayIcon,
            },
            dbPinOrange: {
                url: mapDbPinOrangeIcon,
            },
            dbPinBeige: {
                url: mapDbPinBeigeIcon,
            },
            dbPinGray: {
                url: mapDbPinGrayIcon,
            },
        },
        usc: {
            scanQrCode: {
                url: uscScanQrCode,
                component: UscScanQrCode,
            },
        },
    },
    logo: {
        kolula: {
            url: kolulaLogo,
            component: KolulaLogo,
        },
        kolulaSup: {
            url: kolulaSupLogo,
            component: KolulaSupLogo,
        },
        kolulaWhite: {
            url: kolulaWhiteLogo,
            component: KolulaWhiteLogo,
        },
    },
    unDraw: {
        transferMoney: udTransferMoney,
        warning: udWarning,
    },
};

export default Assets;
