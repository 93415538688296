import {createAction} from 'redux-actions';

export interface FetchLocationsPayload {
}

export interface FetchAvailabilitiesPayload {
    locationId: number;
    autoSelectNextSlot?: boolean;
}

const LocationCommand = {
    fetchLocations: createAction<FetchLocationsPayload>('@@KL_CMD/FETCH_LOCATIONS'),
    fetchAvailabilities: createAction<FetchAvailabilitiesPayload>('@@KL_CMD/FETCH_AVAILABILITIES'),
};

export default LocationCommand;
