import FullScreenDialog from '../../common/FullScreenDialog';
import {Box, IconButton} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import {useHistory} from 'react-router';
import Logo from '../../layout/components/Logo';
import Routes from '../../../routes';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        color: 'white',
        backgroundColor: 'var(--dark-background-color)',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        padding: `${theme.spacing(2)}px 0`,
        [theme.breakpoints.up('lg')]: {
            padding: `${theme.spacing(5)}px 0 ${theme.spacing(4)}px 0`,
        },
    },
    content: {
        flex: 1,
        overflowY: 'auto',
        padding: theme.spacing(2),
        paddingTop: 0,
        boxSizing: 'border-box',
        wordWrap: 'break-word',
    },
    contentWrapper: {
        maxWidth: 1200,
        margin: '0 auto',
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    closeIcon: {
        color: 'white',
    },
}));

interface HintsDialogProps {
    open: boolean;
    onClose?: () => void;
    children: React.ReactNode;
}

const HintsDialog = (props: HintsDialogProps) => {
    const classes = useStyles();
    const history = useHistory();

    const handleCloseButtonClick = props.onClose || (() => {
        history.push(Routes.hintsPath);
    });

    return (
        <FullScreenDialog open={props.open}>
            <Box className={classes.root}>
                <Box className={classes.header}>
                    <Logo center={true} />
                    <IconButton className={classes.closeButton} onClick={handleCloseButtonClick}>
                        <CancelIcon fontSize={'small'} className={classes.closeIcon} />
                    </IconButton>
                </Box>
                <Box className={classes.content}>
                    <Box className={classes.contentWrapper}>
                        {props.children}
                    </Box>
                </Box>
            </Box>
        </FullScreenDialog>
    );
};

export default HintsDialog;
