import * as Sentry from '@sentry/react';

const ErrorTracker = {
    trackException: (error: Error) => {
        if ((error as any).response) {
            const response: any = (error as any).response;

            Sentry.withScope(scope => {
                scope.setExtra('request_method', response.config?.method);
                scope.setExtra('request_url', response.config?.url);
                scope.setExtra('request_data', response.config?.data);
                scope.setExtra('response_data', response.data);
                scope.setExtra('response_headers', response.headers);
                Sentry.captureException(error);
            });

            return;
        }

        Sentry.captureException(error);
    },
    trackMessage: (error: string) => Sentry.captureMessage(error),
};

export default ErrorTracker;
