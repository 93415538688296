import LocationSelector from './locationSelector';
import BookingCreationSelector from './bookingCreationSelector';
import NotificationSelector from './notificationSelector';
import OrderSelector from './orderSelector';

const Selector = {
    Location: LocationSelector,
    BookingCreation: BookingCreationSelector,
    Notification: NotificationSelector,
    Order: OrderSelector,
};

export default Selector;
