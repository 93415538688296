import LocationCommand from './locationCommand';
import BookingCreationCommand from './bookingCreationCommand';
import OrderCommand from './orderCommand';
import NotificationCommand from './notificationCommand';

const Command = {
    Location: LocationCommand,
    BookingCreation: BookingCreationCommand,
    Order: OrderCommand,
    Notification: NotificationCommand,
};

export default Command;
