import { Stripe, loadStripe } from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null>;

export const loadStripeCheckout = () => {
    if (process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
        if (!stripePromise) {
            stripePromise = loadStripe(
                process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
            );
        }
        return stripePromise;
    }
    throw new Error('Stripe publishable key not found');
};
