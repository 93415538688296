import {fork, put, select, take} from 'redux-saga/effects';
import Command from '../action/command';
import Event from '../action/event';
import {onLoadDefaultPersonalData} from './manageDefaultPersonalDataFlow';
import {StartBookingCreationPayload} from '../action/bookingCreationCommand';
import {Action} from 'redux-actions';
import Selector from '../selector';
import {Location} from '../types/location';
import {onEnqueueErrorSnackbar} from './enqueueSnackbarFlow';
import {getI18n} from 'react-i18next';

export const onStartBookingCreation = function*(locationId: number, partner?: 'usc') {
    const location: Location|null = yield select(Selector.Location.makeLocation(locationId));

    if (!location || !location.status.bookable) {
        yield fork(onEnqueueErrorSnackbar, getI18n().t('book_location.error.start_booking'));
        return;
    }

    yield fork(onLoadDefaultPersonalData);
    yield put(Event.BookingCreation.bookingCreationStarted({
        locationId,
        multipleBoardTypes: location.multipleBoardTypes,
        partner,
    }));
};

export function* startBookingCreationFlow() {
    while (true) {
        const action: Action<StartBookingCreationPayload> = yield take(
            Command.BookingCreation.startBookingCreation.toString(),
        );

        yield fork(onStartBookingCreation, action.payload.locationId, action.payload.partner);
    }
}
