import {createAction} from 'redux-actions';
import {InternalOrderId, OrderId} from '../types/order';

export interface FetchOrderPayload {
    internalOrderId: InternalOrderId;
    showFinishedWarning?: boolean;
}

export interface LoadOrdersPayload {
}

export interface StartOrderPayload {
    orderId: OrderId;
}

export interface OpenDoorPayload {
    orderId: OrderId;
    door: number;
}

export interface ReportDamagePayload {
    claim: string;
    doors: number[];
}

export interface FinishOrderPayload {
}

export interface FinalizeOrderPayload {
    orderId: OrderId;
    internalOrderId: InternalOrderId;
}

export interface VerifyIndividualRentalPayload {
    locationId: number;
    door: number;
    password: string;
}

export interface OpenIndividualRentalDoorPayload {
}

const OrderCommand = {
    fetchOrder: createAction<FetchOrderPayload>('@@KL_CMD/FETCH_ORDER'),
    loadOrders: createAction<LoadOrdersPayload>('@@KL_CMD/LOAD_ORDERS'),
    startOrder: createAction<StartOrderPayload>('@@KL_CMD/START_ORDER'),
    openDoor: createAction<OpenDoorPayload>('@@KL_CMD/OPEN_DOOR'),
    reportDamage: createAction<ReportDamagePayload>('@@KL_CMD/REPORT_DAMAGE'),
    finishOrder: createAction<FinishOrderPayload>('@@KL_CMD/FINISH_ORDER'),
    finalizeOrder: createAction<FinalizeOrderPayload>('@@KL_CMD/FINALIZE_ORDER'),
    verifyIndividualRental: createAction<VerifyIndividualRentalPayload>('@@KL_CMD/VERIFY_INDIVIDUAL_RENTAL'),
    openIndividualRentalDoor: createAction<OpenIndividualRentalDoorPayload>('@@KL_CMD/OPEN_INDIVIDUAL_RENTAL_DOOR'),
};

export default OrderCommand;
