import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Order} from '../../../types/order';
import {useTranslation} from 'react-i18next';
import {Box} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Assets from '../../../assets/assets';
import RentalTime from './RentalTime';
import {useSelector} from 'react-redux';
import Selector from '../../../selector';

const KolulaWhiteLogo = Assets.logo.kolulaWhite.component;


const useStyles = makeStyles(theme => ({
    root: {
        width: 296,
        height: 386,
        background: theme.palette.primary.main,
        color: 'white',
        borderRadius: 10,
        position: 'relative',
        margin: `0 ${theme.spacing(0.25)}px`,
        overflow: 'hidden',
        /* Scale ticket down for iPhone 5; not optimal but usable */
        '@media only screen and (max-height: 555px)': {
            transform: 'scale(0.75)',
            transformOrigin: 'top',
        },
        [theme.breakpoints.up('md')]: {
            width: 350,
            height: 455,
        },
    },
    header: {
        margin: `${theme.spacing(1)}px ${theme.spacing(2)}px 0 0`,
        textAlign: 'right',
    },
    bookingNumber: {
        margin: `-2px ${theme.spacing(2)}px 0 0`,
    },
    headerWrapper: {
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'space-between',

    },
    headline: {
        margin: `${theme.spacing(2)}px 0`,
        fontWeight: 'bold',
        padding: `0 ${theme.spacing(2)}px`,
        fontSize: '26px',
    },
    orderItem: {
        padding: '0 15px',
    },
    upperCase: {
        textTransform: 'uppercase',
    },
    headerLogo: {
        width: '90px',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    ticket_dot_left: {
        width: '5px',
        height: '10px',
        backgroundColor: '#54595f',
        position: 'absolute',
        left: 0,
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
        top: '29%',
    },
    ticket_dot_right: {
        width: '5px',
        height: '10px',
        backgroundColor: '#54595f',
        position: 'absolute',
        right: 0,
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
        top: '29%',
    },
}));

interface OrderTicketProps {
    order: Order;
}

const OrderTicket = (props: OrderTicketProps) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const location = useSelector(Selector.Location.makeLocation(props.order.locationId));

    return (
        <Box className={classes.root} style={props.order.status === 'processing' ? { opacity: 0.5 } : {}}>
            <Box className={classes.ticket_dot_left} />
            <Box className={classes.headerWrapper}>
                <Box className={classes.headerLogo}>
                    <KolulaWhiteLogo />
                </Box>
                <Box>
                    <Typography variant={'body2'} className={classes.header}>
                        {t('orders.booking_number')}
                    </Typography>
                    <Typography variant={'body2'} className={classes.bookingNumber}>
                        {props.order.orderId}
                    </Typography>
                </Box>
            </Box>

            <Typography variant={'h2'} className={classes.headline}>
                {t('locations.location')} {props.order.locationName}
            </Typography>

            <RentalTime
                startDateTime={props.order.startDateTime}
                endDateTime={props.order.endDateTime}
                preparationTime={location?.preparationTime || 0}
            />

            <Box className={classes.orderItem}>
                <Box style={{marginTop: '20px'}}>
                    {t('locations.location')}
                </Box>
                <Typography style={{fontWeight: 'bold', lineHeight: 1.2}}>
                    {props.order.locationName}
                </Typography>
                <Typography style={{ marginTop: '4px' }}>
                    {t('book_location.board_selection.rented_boards')}
                </Typography>
                {props.order.boards.map((board, index) => (
                    <Typography style={{fontWeight: 'bold', lineHeight: 1.2}} key={index}>
                        {board.quantity}&times; {board.type}
                    </Typography>
                ))}
            </Box>
            <Box className={classes.ticket_dot_right} />
        </Box>
    );
};

export default OrderTicket;
