import createSagaMiddleware from 'redux-saga';
import {applyMiddleware, createStore} from 'redux';
import rootSaga from './saga';
import {initialState, rootReducer} from './reducer';
import * as Sentry from '@sentry/react';
import Config from './util/config';
import {composeWithDevTools} from '@redux-devtools/extension';

const sagaMiddleware = createSagaMiddleware();
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const finalEnhancer = Config.environment !== 'development'
    ? composeWithDevTools(applyMiddleware(sagaMiddleware), sentryReduxEnhancer)
    : composeWithDevTools(applyMiddleware(sagaMiddleware));

export const store = createStore(
    rootReducer,
    initialState,
    finalEnhancer,
);

sagaMiddleware.run(rootSaga);
