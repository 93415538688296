import React, {useEffect, useState} from 'react';
import {Box, Button, useMediaQuery} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import Command from '../../action/command';
import Selector from '../../selector';
import {useHistory} from 'react-router';
import Routes from '../../routes';
import {useTranslation} from 'react-i18next';
import AddNewOrderForm from './components/AddNewOrderForm';
import LoaderOverlay from '../layout/components/LoaderOverlay';
import Logo from '../layout/components/Logo';
import OrdersSwiper from './components/OrdersSwiper';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        paddingTop: '16px',
        boxSizing: 'border-box',
        backgroundColor: 'var(--dark-background-color)',
    },
    contentWrapper: {
        height: 'calc(100% - 66px)',
        maxWidth: 1000,
        margin: '0 auto',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    buttonWrapper: {
        height: '66px',
        maxWidth: 1000,
        margin: '0 auto',
        padding: '12px 16px',
        boxSizing: 'border-box',
    },
}));

const OrdersPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    const orders = useSelector(Selector.Order.orders);
    const ordersLoaded = useSelector(Selector.Order.ordersLoaded);
    const fetchingOrder = useSelector(Selector.Order.fetchingOrder);
    const [orderIndex, setOrderIndex] = useState<number>(0);
    const [orderNumber, setOrderNumber] = useState<string>('');
    const extremelySmallDevice = useMediaQuery('@media(max-width: 350px)');

    const showAddOrderView = ordersLoaded && orders.length <= 0;

    useEffect(
        () => {
            dispatch(Command.Order.loadOrders({}));
        },
        [],
    );

    const useOrder = () => {
        if (!orders[orderIndex]) {
            return;
        }

        if (orders[orderIndex].rental?.finishedAt) {
            dispatch(Command.Notification.enqueueNotification({
                severity: 'warning',
                message: t('orders.warning.booking_finished'),
            }));
        } else if (orders[orderIndex].status === 'approved') {
            history.push(Routes.makeUseOrderUrl(orders[orderIndex].id));
        } else {
            dispatch(Command.Notification.enqueueNotification({
                severity: 'info',
                message: t('orders.booking_not_ready_info'),
            }));
            dispatch(Command.Order.fetchOrder({ internalOrderId: orders[orderIndex].orderId }));
        }
    };

    const addNewOrder = () => {
        dispatch(Command.Order.fetchOrder({ internalOrderId: orderNumber, showFinishedWarning: true }));
        setOrderNumber('');
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.contentWrapper}>
                {!extremelySmallDevice && <Logo center={true} />}
                {(ordersLoaded && !showAddOrderView) && (
                    <OrdersSwiper orders={orders} index={orderIndex} onChangeIndex={setOrderIndex} />
                )}
                {(ordersLoaded && showAddOrderView) && (
                    <AddNewOrderForm value={orderNumber} onChange={setOrderNumber} fullHeight={true} />
                )}
            </Box>
            <Box className={classes.buttonWrapper}>
                <Button
                    variant={'contained'}
                    fullWidth={true}
                    onClick={showAddOrderView ? addNewOrder : useOrder}
                    disabled={showAddOrderView && orderNumber.length < 10}
                    children={t('orders.lets_go')}
                />
            </Box>
            <LoaderOverlay open={showAddOrderView && fetchingOrder} />
        </Box>
    );
};

export default OrdersPage;
