import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        backgroundColor: 'var(--dark-background-color)',
    },
    wrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 1000,
        padding: `0 ${theme.spacing(2)}px`,
        boxSizing: 'border-box',
        margin: '0 auto',
        overflow: 'hidden',
    },
    contentWrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
    },
    innerContentWrapper: {
        margin: 'auto',
    },
}));

interface PageProps {
    header: React.ReactNode;
    footer: React.ReactNode;
    children: React.ReactNode;
}

export const Page = (props: PageProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.wrapper}>
                {props.header}

                <Box className={classes.contentWrapper}>
                    <Box className={classes.innerContentWrapper}>
                        {props.children}
                    </Box>
                </Box>

                {props.footer}
            </Box>
        </Box>
    );
};

export default Page;
