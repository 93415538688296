
export const isValidEmail = (email: string) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
export const isValidUscCustomerId = (uscCustomerId: string) => /^[0-9]{8,10}$/.test(uscCustomerId);

/**
 * We only test that the length is reasonable.
 * Additionally, we also ensure that a German phone number does not also include the local 0-prefix (so no 490<number>)
 * @param phone
 */
export const isValidPhone = (phone: string) => /^(?!490)[0-9]{7,15}$/.test(phone);
