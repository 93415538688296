import {AvailabilitySlot, LocationAvailability, Options} from '../../../types/location';
import moment from 'moment';
import {SpecificOption} from '../components/BoardSelectionStep/BoardSelectionItem';

// @todo some these should probably be moved to the Selectors

/**
 * Checks if a given date is available for booking
 *
 * @param availability
 * @param date
 * @param isPartnerUsc
 */
export const isDateAvailable = (
    availability: LocationAvailability,
    date: moment.Moment,
    isPartnerUsc?: boolean,
): boolean => {
    // Note: Allowed days for partner USC are Mo (1) through Fr (5); Sa (6) and Su (7) are supposed to be disabled
    if (isPartnerUsc && date.isoWeekday() > 5) {
        return false;
    }

    if (!availability.availabilities[date.format('YYYY-MM-DD')]) {
        return false;
    }

    return getAvailableTimeSlots(availability, date).length > 0;
};

/**
 * Returns all time slots on the given date with at least one duration that has at least one available board
 *
 * @param availability
 * @param selectedDate
 */
export const getAvailableTimeSlots = (
    availability: LocationAvailability,
    selectedDate: moment.Moment,
): string[] => {
    const dateKey = selectedDate.format('YYYY-MM-DD');
    const allTimeSlots = Object.keys(availability.availabilities[dateKey]);

    return allTimeSlots
        .filter(timeKey => {
            const timeSlot: AvailabilitySlot = availability.availabilities[dateKey][timeKey];

            for (const durationRecord of Object.values(timeSlot)) {
                for (const availableCount of Object.values(durationRecord)) {
                    if (availableCount > 0) {
                        return true;
                    }
                }
            }

            return false;
        })
        .sort();
};

/**
 * Returns all durations on the given date and time-slot with at least on available board
 *
 * @param availability
 * @param selectedDate
 * @param selectedTime
 * @param isPartnerUsc
 */
export const getAvailableDurations = (
    availability: LocationAvailability,
    selectedDate: moment.Moment,
    selectedTime: string,
    isPartnerUsc?: boolean,
): number[] => {
    const dateKey = selectedDate!.format('YYYY-MM-DD');
    const timeSlot: AvailabilitySlot = availability.availabilities[dateKey][selectedTime];

    const durations: number[] = [];

    for (const boardTypeId in timeSlot) {
        for (const durationId in timeSlot[boardTypeId]) {
            if (timeSlot[boardTypeId][durationId] < 1) {
                continue;
            }

            const durationValue = availability.options[boardTypeId]![durationId]!.duration;

            // Note: For partner USC only a duration of 1 hour is available
            if (isPartnerUsc && durationValue !== 1) {
                continue;
            }

            if (!durations.includes(durationValue)) {
                durations.push(durationValue);
            }
        }
    }

    return durations.sort();
};

/**
 * Returns the sum of the available boards of all price lists that match date, time-slot and duration
 *
 * @param availability
 * @param selectedDate
 * @param selectedTime
 * @param selectedDuration
 */
export const getMaxPaddlerCount = (
    availability: LocationAvailability,
    selectedDate: moment.Moment,
    selectedTime: string,
    selectedDuration: number,
): number => {
    const dateKey = selectedDate!.format('YYYY-MM-DD');
    const timeSlot: AvailabilitySlot = availability.availabilities[dateKey][selectedTime];

    const possibleDurationIds = getPossibleDurationIdsForDuration(availability.options, selectedDuration);

    let availableCount = 0;
    Object.values(timeSlot).forEach(durationRecord => {
        possibleDurationIds.forEach(possibleDurationId => {
            availableCount += durationRecord[possibleDurationId] || 0;
        });
    });

    return availableCount;
};

/**
 * Returns all durationIds that have matching duration value to the given duration (equal period)
 *
 * @param options
 * @param duration
 */
export const getPossibleDurationIdsForDuration = (options: Options, duration: number): string[] => {
    const possibleDurationIds: string[] = [];

    Object.values(options).forEach(option => {
        for (const durationId in option) {
            if (option[durationId].duration === duration) {
                possibleDurationIds.push(durationId);
            }
        }
    });

    return possibleDurationIds;
};

/**
 * Returns all possible board options for a given date, time and duration
 *
 * @param availability
 * @param selectedDate
 * @param selectedTime
 * @param selectedDuration
 */
export const getPossibleOptions = (
    availability: LocationAvailability,
    selectedDate: moment.Moment,
    selectedTime: string,
    selectedDuration: number,
): SpecificOption[] => {
    const possibleOptions: SpecificOption[] = [];

    const dateKey = selectedDate!.format('YYYY-MM-DD');
    const timeSlot: AvailabilitySlot = availability.availabilities[dateKey][selectedTime];
    const boardIds = Object.keys(timeSlot);

    boardIds.forEach(boardId => {
        const options = availability.options[boardId];
        Object.values(options).forEach(option => {
            const maxQuantity = timeSlot[boardId][option.id];

            if (option.duration === selectedDuration && maxQuantity > 0) {
                possibleOptions.push({
                    id: option.id,
                    price: option.price,
                    duration: option.duration,
                    name: option.name,
                    description: option.description,
                    maxQuantity: timeSlot[boardId][option.id],
                });
            }
        });
    });

    return possibleOptions;
};

export const getMinPossiblePrice = (
    availability: LocationAvailability,
    selectedDate: moment.Moment,
    selectedTime: string,
    selectedDuration: number,
): number => {
    const options = getPossibleOptions(availability, selectedDate, selectedTime, selectedDuration);
    return Math.min(...options.map(option => Number(option.price)));
};
