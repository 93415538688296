import React from 'react';
import {Backdrop, CircularProgress} from '@material-ui/core';

interface LoaderOverlayProps {
    open: boolean;
}

const LoaderOverlay = (props: LoaderOverlayProps) => {
    return (
        <Backdrop open={props.open} style={{ zIndex: 2 }}>
            <CircularProgress color={'secondary'} />
        </Backdrop>
    );
};

export default LoaderOverlay;
