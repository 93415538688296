import { all, call, delay, spawn } from 'redux-saga/effects';
import Logger from './util/logger';
import {fetchLocationsFlow} from './saga/fetchLocationsFlow';
import {fetchAvailabilitiesFlow} from './saga/fetchAvailabilitiesFlow';
import {redeemCouponFlow} from './saga/redeemCouponFlow';
import {createNewReservationFlow} from './saga/createNewReservationFlow';
import {cancelBookingCreationFlow} from './saga/cancelBookingCreationFlow';
import {startBookingCreationFlow} from './saga/startBookingCreationFlow';
import {loadOrdersFlow} from './saga/manageOrdersFlow';
import {fetchOrderFlow} from './saga/fetchOrderFlow';
import {startOrderFlow} from './saga/startOrderFlow';
import {openDoorFlow} from './saga/openDoorFlow';
import {finishOrderFlow} from './saga/finishOrderFlow';
import {reportDamageFlow} from './saga/reportDamageFlow';
import ErrorTracker from './util/errorTracker';
import {enqueueSnackbarFlow} from './saga/enqueueSnackbarFlow';
import {removeReservationFlow} from './saga/removeReservationFlow';
import {calculateDefaultBoardTypeCountsFlow} from './saga/calculateDefaultBoardTypeCountsFlow';
import {verifyIndividualRentalFlow} from './saga/verifyIndividualRentalFlow';
import {openIndividualRentalDoorFlow} from './saga/openIndividualRentalDoorFlow';
import {finalizeOrderFlow} from './saga/finalizeOrderFlow';
import {initializePaymentFlow} from './saga/payment/initializePaymentFlow';
import {completeBraintreePaymentFlow} from './saga/payment/completeBraintreePaymentFlow';
import {completeVippsPaymentFlow} from './saga/payment/completeVippsPaymentFlow';
import {completeStripePaymentFlow} from './saga/payment/completeStripePaymentFlow';

const SagaLogger = Logger.for('Saga');

/**
 * Prevents the root saga from terminating entirely due to some error in another saga
 *
 * @param saga
 */
const makeRestartable = (saga: any) => {
    return function*() {
        yield spawn(function*() {
            while (true) {
                try {
                    yield call(saga);
                    SagaLogger.error(
                        'Unexpected root saga termination. The root sagas should live during the whole app lifetime!',
                        saga,
                    );
                    ErrorTracker.trackMessage('Unexpected root saga termination');
                } catch (e) {
                    ErrorTracker.trackException(e);
                    SagaLogger.error('Saga error, the saga will be restarted', e);
                }
                yield delay(1000); // Workaround to avoid infinite error loops
            }
        });
    };
};

const sagas: any = [
    fetchLocationsFlow,
    fetchAvailabilitiesFlow,
    redeemCouponFlow,
    calculateDefaultBoardTypeCountsFlow,
    createNewReservationFlow,
    removeReservationFlow,
    cancelBookingCreationFlow,
    startBookingCreationFlow,
    loadOrdersFlow,
    fetchOrderFlow,
    startOrderFlow,
    openDoorFlow,
    reportDamageFlow,
    finishOrderFlow,
    finalizeOrderFlow,
    enqueueSnackbarFlow,
    verifyIndividualRentalFlow,
    openIndividualRentalDoorFlow,

    /* payment */
    initializePaymentFlow,
    completeBraintreePaymentFlow,
    completeVippsPaymentFlow,
    completeStripePaymentFlow,
].map(makeRestartable);

export default function* rootSaga() {
    SagaLogger.log('Root saga started');
    yield all(sagas.map((saga: any) => call(saga)));
}
