import {call, fork, select, take} from 'redux-saga/effects';
import Command from '../action/command';
import Api from '../api';
import Logger from '../util/logger';
import {onEnqueueErrorSnackbar} from './enqueueSnackbarFlow';
import {getI18n} from 'react-i18next';
import Selector from '../selector';
import ErrorTracker from '../util/errorTracker';
import {IndividualRentalState} from '../reducer/orderReducer';

export const onOpenIndividualRentalDoor = function*() {
    const individualRental: IndividualRentalState = yield select(Selector.Order.individualRental);

    if (!individualRental.data) {
        yield fork(onEnqueueErrorSnackbar, getI18n().t('individual_rent.error.open_door'));
        return;
    }

    try {
        yield call(
            Api.openDoor,
            individualRental.data.locationId,
            individualRental.data.door,
            individualRental.data.token,
        );
    } catch (error) {
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);
        yield fork(onEnqueueErrorSnackbar, getI18n().t('individual_rent.error.open_door'));
    }
};

export function* openIndividualRentalDoorFlow() {
    while (true) {
        yield take(Command.Order.openIndividualRentalDoor.toString());
        yield fork(onOpenIndividualRentalDoor);
    }
}
