import {fork, put, take} from 'redux-saga/effects';
import Command from '../action/command';
import {Action} from 'redux-actions';
import {FinalizeOrderPayload} from '../action/orderCommand';
import Event from '../action/event';

export const onFinalizeOrder = function*(payload: FinalizeOrderPayload) {
    yield put(Event.Order.orderFinalized({ orderId: payload.orderId }));
    yield put(Command.Order.fetchOrder({ internalOrderId: payload.internalOrderId }));
};

export function* finalizeOrderFlow() {
    while (true) {
        const action: Action<FinalizeOrderPayload> = yield take(Command.Order.finalizeOrder.toString());
        yield fork(onFinalizeOrder, action.payload);
    }
}
