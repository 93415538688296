import {ReduxState} from '../reducer';
import {Order, OrderId, Rental} from '../types/order';
import {IndividualRentalState} from '../reducer/orderReducer';

const stateKey = 'order';

const OrderSelector = {
    orders: (state: ReduxState) => Object.values(state[stateKey].orders).sort((order1, order2) => {
        if (order1.startDateTime.getTime() < order2.startDateTime.getTime()) {
            return -1;
        } else if (order1.startDateTime.getTime() > order2.startDateTime.getTime()) {
            return 1;
        } else {
            /* Make sure to have a consistent sorting order for bookings with identical start time */
            if (order1.id < order2.id) return -1;
            else if (order1.id > order2.id) return 1;
            else return 0;
        }
    }),
    ordersLoaded: (state: ReduxState) => state[stateKey].ordersLoaded,
    rental: (state: ReduxState): Rental|null => state[stateKey].rental,
    fetchingOrder: (state: ReduxState): boolean => state[stateKey].fetchingOrder,
    startingOrder: (state: ReduxState): boolean => state[stateKey].startingOrder,
    doorsVisibleTimestamp: (state: ReduxState): Date|null => state[stateKey].doorsVisibleTimestamp,
    orderFinished: (state: ReduxState): boolean => state[stateKey].orderFinished,
    reportingDamage: (state: ReduxState): boolean => state[stateKey].reportingDamage,
    individualRental: (state: ReduxState): IndividualRentalState => state[stateKey].individualRental,

    makeOrder: (orderId: OrderId) => (state: ReduxState): Order|null =>
        OrderSelector.orders(state).find(order => order.id === orderId) || null,
};

export default OrderSelector;
