import {combineReducers} from 'redux';
import {
    LocationState,
    initialState as locationInitialState,
    reducer as locationReducer,
} from './reducer/locationReducer';
import {
    BookingCreationState,
    initialState as bookingCreationInitialState,
    reducer as bookingCreationReducer,
} from './reducer/bookingCreationReducer';
import {
    NotificationState,
    initialState as notificationInitialState,
    reducer as notificationReducer,
} from './reducer/notificationReducer';
import {
    OrderState,
    initialState as orderInitialState,
    reducer as orderReducer,
} from './reducer/orderReducer';

export interface ReduxState {
    location: LocationState;
    bookingCreation: BookingCreationState;
    notification: NotificationState;
    order: OrderState;
}

export const initialState: ReduxState = {
    location: locationInitialState,
    bookingCreation: bookingCreationInitialState,
    notification: notificationInitialState,
    order: orderInitialState,
};

export const rootReducer = combineReducers({
    location: locationReducer,
    bookingCreation: bookingCreationReducer,
    notification: notificationReducer,
    order: orderReducer,
});
