import {Slide} from '@material-ui/core';
import React, {useEffect} from 'react';

interface FullScreenDialogProps {
    open: boolean;
    children: React.ReactNode;
}

const FullScreenDialog = (props: FullScreenDialogProps) => {
    useEffect(() => {
        document.body.style.backgroundColor = props.open
            ? 'var(--dark-background-color)' : 'var(--toolbar-background-color)';
    }, [props.open]);

    return (
        <Slide in={props.open} direction={'up'} mountOnEnter={true} unmountOnExit={true}>
            <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, zIndex: 11, overflow: 'none' }}>
                {props.children}
            </div>
        </Slide>
    );
};

export default FullScreenDialog;
