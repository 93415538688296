import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Selector from '../../selector';
import {useEffect, useState} from 'react';
import {Snackbar} from '@material-ui/core';
import Event from '../../action/event';
import InfoBox from '../layout/components/InfoBox';

const Notifier = () => {
    const dispatch = useDispatch();
    const notification = useSelector(Selector.Notification.currentNotification);
    const [open, setOpen] = useState<boolean>(false);

    const closeNotification = () => {
        if (notification) {
            dispatch(Event.Notification.notificationDequeued({id: notification.id}));
        }
    };

    useEffect(() => {
        setOpen(false);

        if (notification) {
            setTimeout(() => setOpen(true), 70);
            setTimeout(closeNotification, notification.autoHideDuration);
        }
    }, [notification]);

    return (
        <Snackbar
            key={notification?.id + (open ? 'open' : 'closed')}
            open={open}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            children={(
                <InfoBox variant={notification?.severity} text={notification?.message} onClose={closeNotification} />
            )}
        />
    );
};

export default Notifier;
