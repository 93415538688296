import React from 'react';
import {Box, TextField, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        boxSizing: 'border-box',
        marginTop: theme.spacing(3),
        padding: theme.spacing(2),
    },
    header: {
        color: 'white',
    },
    input: {
        marginTop: theme.spacing(3),
        '& .MuiFormLabel-root': {
            color: 'white',
        },
        '& .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
        },
        '& .MuiInputBase-input': {
            color: 'white',
        },
    },
    info1: {
        textAlign: 'center',
        marginTop: theme.spacing(3),
        color: 'white',
    },
    info2: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
        color: 'white',
    },
}));

interface AddNewOrderFormProps {
    value: string;
    onChange: (value: string) => void;
    fullHeight?: boolean;
}

const AddNewOrderForm = (props: AddNewOrderFormProps) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;

        if (!newValue.match(/^[0-9]*$/)) {
            return;
        }

        props.onChange(newValue);
    };

    return (
        <Box className={classes.root} style={props.fullHeight ? { height: '100%' } : undefined}>
            <Typography variant={'h1'} className={classes.header}>
                {t('orders.header.enter_ticket_number')}
            </Typography>
            <TextField
                className={classes.input}
                fullWidth={true}
                label={t('orders.ticket_number')}
                variant={'outlined'}
                color={'primary'}
                value={props.value}
                onChange={handleOnChange}
                inputProps={{
                    type: 'text',
                    pattern: '[0-9]*',
                }}
            />
            <Typography className={classes.info1}>
                {t('orders.no_valid_orders')}
            </Typography>
            <Typography variant={'body2'} className={classes.info2}>
                {t('orders.enter_booking_number_info')}
            </Typography>
        </Box>
    );
};

export default AddNewOrderForm;
