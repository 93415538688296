import {Box, useTheme} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    square: {
        width: '31px',
        height: '30px',
        position: 'absolute',
    },
    rect: {
        width: '30px',
        height: '92px',
        position: 'absolute',
    },
    number: {
        position: 'absolute',
        top: '37%',
        left: '24%',
        color: 'white',
        fontWeight: 'bold',
    },
}));

interface KolulaBoxDoorProps {
    top: number;
    left: number;
    orientation: 'left'|'right';
    doorNumber: number;
    active: boolean;
}

const KolulaBoxDoor = (props: KolulaBoxDoorProps) => {
    const classes = useStyles();
    const theme = useTheme();

    return (<>
        <Box
            className={classes.square}
            style={{
                left: props.left + (props.orientation === 'right' ? 30 : 0),
                top: props.top + 31,
                backgroundColor: props.active ? theme.palette.secondary.main : theme.palette.primary.main,
            }}
        />

        <Box
            className={classes.rect}
            style={{
                left: props.left + (props.orientation === 'right' ? 0 : 31),
                top: props.top,
                backgroundColor: props.active ? theme.palette.secondary.main : theme.palette.primary.main,
            }}
        >
            <Typography className={classes.number}>{props.doorNumber}</Typography>
        </Box>
    </>);
};

export default KolulaBoxDoor;
