import {fork, put, select, take} from 'redux-saga/effects';
import Command from '../action/command';
import Selector from '../selector';
import {SpecificOption} from '../modules/bookLocation/components/BoardSelectionStep/BoardSelectionItem';
import {getPossibleOptions} from '../modules/bookLocation/util/selectionStepHelpers';
import {LocationAvailability} from '../types/location';
import {BookingCreationSelection} from '../reducer/bookingCreationReducer';
import Event from '../action/event';

export const onCalculateDefaultBoardTypeCounts = function*() {
    const locationId: number|null = yield select(Selector.BookingCreation.locationId);
    const selection: BookingCreationSelection = yield select(Selector.BookingCreation.selection);
    const locationAvailability: LocationAvailability|null = yield select(
        Selector.Location.makeLocationAvailabilities(locationId || 0),
    );

    if (!locationAvailability || !selection.date || !selection.time || !selection.duration) {
        return;
    }

    const possibleOptions: SpecificOption[] = getPossibleOptions(
        locationAvailability,
        selection.date,
        selection.time,
        selection.duration,
    );

    if (possibleOptions.length < 1) {
        return;
    }

    let paddlerCountToDistribute = selection.paddlerCount;
    for (const option of possibleOptions) {
        if (paddlerCountToDistribute < 1) {
            break;
        }

        yield put(Event.BookingCreation.boardTypeCountChanged({
            id: option.id,
            count: Math.min(paddlerCountToDistribute, option.maxQuantity),
        }));

        paddlerCountToDistribute -= option.maxQuantity;
    }
};

export function* calculateDefaultBoardTypeCountsFlow() {
    while (true) {
        yield take(Command.BookingCreation.calculateDefaultBoardTypeCounts.toString());
        yield fork(onCalculateDefaultBoardTypeCounts);
    }
}
