import {createMuiTheme} from '@material-ui/core';
import {palette} from './palette';
import {makeTypography} from './typography';
import MuiButton from './overrides/MuiButton';

export const theme = createMuiTheme({
    palette,
    typography: makeTypography(palette),
    overrides: {
        MuiButton,
    },
});
