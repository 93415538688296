import {Box, IconButton, Typography} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import SuccessIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutlineOutlined';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';

const useStyles = makeStyles(theme => ({
    root: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        position: 'relative',
        color: 'white',
        borderRadius: 4,
    },
    contentSpacing: {
        marginRight: theme.spacing(2),
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
    },
    info: {
        backgroundColor: theme.palette.info.main,
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
    },
    error: {
        backgroundColor: theme.palette.error.main,
    },
    success: {
        backgroundColor: theme.palette.success.main,
    },
    icon: {
        position: 'absolute',
        top: '8px',
    },
    closeButton: {
        position: 'absolute',
        top: 4,
        right: 4,
    },
    closeButtonIcon: {
        color: 'white',
    },
}));

interface InfoBoxProps {
    variant: 'primary'|'secondary'|'info'|'warning'|'error'|'success';
    text: string;
    onClose?: () => void;
}

const InfoBox = (props: InfoBoxProps) => {
    const classes = useStyles();

    const IconComponent = (() => {
        switch (props.variant) {
            case 'info': return InfoIcon;
            case 'warning': return WarningIcon;
            case 'error': return ErrorIcon;
            case 'success': return SuccessIcon;
            default: return null;
        }
    })();

    return (
        <Box className={`${classes.root} ${classes[props.variant]}`}>
            {IconComponent && <IconComponent className={classes.icon} />}
            <Typography className={props.onClose ? classes.contentSpacing : undefined}>
                {IconComponent && <span style={{ display: 'inline-block', width: '30px' }}> </span>}
                {props.text}
            </Typography>

            {props.onClose && (
                <IconButton className={classes.closeButton} onClick={props.onClose} size={'small'}>
                    <CancelIcon fontSize={'small'} className={classes.closeButtonIcon} />
                </IconButton>
            )}
        </Box>
    );
};

export default InfoBox;
