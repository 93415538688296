import {Box} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        padding: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px 0`,
        [theme.breakpoints.up('lg')]: {
            padding: `${theme.spacing(4)}px 0 ${theme.spacing(5)}px 0`,
        },
    },
}));

interface FooterProps {
    children: React.ReactNode;
}

const Footer = (props: FooterProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            {props.children}
        </Box>
    );
};

export default Footer;
