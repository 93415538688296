import {createAction} from 'redux-actions';

export interface NotificationEnqueuedPayload {
    id: string;
    severity: 'error'|'warning'|'info'|'success';
    message: string;
    autoHideDuration: number;
}

export interface NotificationDequeuedPayload {
    id: string;
}

const NotificationEvent = {
    notificationEnqueued: createAction<NotificationEnqueuedPayload>('@@KL_EVT/NOTIFICATION_ENQUEUED'),
    notificationDequeued: createAction<NotificationDequeuedPayload>('@@KL_EVT/NOTIFICATION_DEQUEUED'),
};

export default NotificationEvent;
