const Routes = {
    locationsPath: '/locations',
    locationPath: '/locations/:locationId',
    bookLocationPath: '/locations/:locationId/book',

    ordersPath: '/orders',
    useOrderPath: '/orders/:orderId',
    reportDamagePath: '/orders/:orderId/report-damage',
    orderFaqsPath: '/orders/:orderId/faq',

    vippsPaymentReturnPath: '/payments/vipps/return',
    stripePaymentReturnPath: '/payments/stripe/return',

    hintsPath: '/hints',
    tipsPath: '/hints/hints-and-tips',
    pricePath: '/hints/pricing',
    faqsPath: '/hints/faq',
    addOrderPath: '/hints/add-order',
    termsOfServicePath: '/hints/terms-of-service',
    dataProtectionPath: '/hints/data-protection',
    imprintPath: '/hints/imprint',

    individualRent: '/rent/locations/:locationId/doors/:door',

    makeLocationPath: (locationId: number) => Routes.locationPath.replace(':locationId', String(locationId)),
    makeBookLocationUrl: (locationId: number) => Routes.bookLocationPath.replace(':locationId', String(locationId)),
    makeUseOrderUrl: (orderId: string) => Routes.useOrderPath.replace(':orderId', orderId),
    makeReportDamageUrl: (orderId: string) => Routes.reportDamagePath.replace(':orderId', orderId),
    makeOrderFaqsUrl: (orderId: string) => Routes.orderFaqsPath.replace(':orderId', orderId),
    makeIndividualRentUrl: (locationId: number, door: number) => Routes.individualRent
        .replace(':locationId', String(locationId))
        .replace(':door', String(door)),
};

export default Routes;
