import {createAction} from 'redux-actions';

export interface EnqueueNotificationPayload {
    severity: 'error'|'warning'|'info'|'success';
    message: string;
    autoHideDuration?: number;
}

const NotificationCommand = {
    enqueueNotification: createAction<EnqueueNotificationPayload>('@@KL_CMD/ENQUEUE_NOTIFICATION'),
};

export default NotificationCommand;
