import {call, select} from 'redux-saga/effects';
import Selector from '../../selector';
import {Location, Option} from '../../types/location';
import GTM from '../../util/gtm';
import {Reservation} from '../../types/bookingCreation';
import ErrorTracker from '../../util/errorTracker';
import Logger from '../../util/logger';

const mapProducts = function*(callback: (product: any) => void) {
    const locationId: number|null = yield select(Selector.BookingCreation.locationId);
    if (!locationId) {
        return;
    }

    const location: Location|null = yield select(Selector.Location.makeLocation(locationId));
    const boardSelection: Record<string, number> = yield select(Selector.BookingCreation.boardSelection);

    for (const optionId of Object.keys(boardSelection)) {
        const option: Option|null = yield select(Selector.Location.makeOption(locationId, optionId));

        if (option && location) {
            callback({
                location,
                option,
                quantity: boardSelection[optionId],
            });
        }
    }
};

export const trackGTMAddToCart = function*() {
    try {
        yield call(mapProducts, product => {
            GTM.trackEecAddToCart(product);
            GTM.trackFacebookAddToCart(product);
        });
    } catch (error) {
        ErrorTracker.trackException(error);
        Logger.error(error);
    }
};

export const trackGTMRemoveFromCart = function*() {
    try {
        yield call(mapProducts, product => {
            GTM.trackEecRemoveFromCart(product);
        });
    } catch (error) {
        ErrorTracker.trackException(error);
        Logger.error(error);
    }
};

export const trackGTMPlaceOrder = function*(internalOrderId: string) {
    try {
        const products: any[] = [];
        yield call(mapProducts, product => products.push(product));
        const reservation: Reservation = yield select(Selector.BookingCreation.reservation);

        yield call(GTM.trackEecPlaceOrder, internalOrderId, reservation, products);
    } catch (error) {
        ErrorTracker.trackException(error);
        Logger.error(error);
    }
};
