import {Box, IconButton} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SwipeableViews from 'react-swipeable-views';
import OrderTicket from './OrderTicket';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';
import {Order} from '../../../types/order';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        overflowY: 'auto',
    },
    leftButtonWrapper: {
        display: 'none',
        width: 80,
        justifyContent: 'flex-start',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    rightButtonWrapper: {
        width: 80,
        display: 'none',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    swiperButton: {
        alignSelf: 'center',
        color: 'white',
    },
    swiperButtonIcon: {
        fontSize: 50,
    },
    swipeableViews: {
        padding: '0 10px',
        width: '300px',
        overflow: 'visible',
        [theme.breakpoints.up('md')]: {
            width: '354px',
            padding: '0 100px',
        },
    },
}));

interface OrdersSwiperProps {
    orders: Order[];
    index: number;
    onChangeIndex: (index: number) => void;
}

const OrdersSwiper = (props: OrdersSwiperProps) => {
    const classes = useStyles();

    const showLeftButton = props.index !== 0;
    const showRightButton = props.index !== props.orders.length - 1;

    return (
        <Box className={classes.root}>
            <Box className={classes.leftButtonWrapper}>
                {showLeftButton && (
                    <IconButton
                        className={classes.swiperButton}
                        onClick={() => props.onChangeIndex(props.index - 1)}
                        children={<ChevronLeftIcon className={classes.swiperButtonIcon} />}
                    />
                )}
            </Box>
            <SwipeableViews
                className={classes.swipeableViews}
                resistance={true}
                enableMouseEvents={true}
                index={props.index}
                onChangeIndex={index => props.onChangeIndex(index)}
                children={props.orders.map(order => (
                    <OrderTicket key={order.id} order={order} />
                ))}
            />
            <Box className={classes.rightButtonWrapper}>
                {showRightButton && (
                    <IconButton
                        className={classes.swiperButton}
                        onClick={() => props.onChangeIndex(props.index + 1)}
                        children={<ChevronRightIcon className={classes.swiperButtonIcon} />}
                    />
                )}
            </Box>
        </Box>
    );
};

export default OrdersSwiper;
