import React from 'react';
import {Box, IconButton, Link} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {useTranslation} from 'react-i18next';
import Routes from '../../../routes';
import {OrderId} from '../../../types/order';
import {Link as RouterLink} from 'react-router-dom';
import Config from '../../../util/config';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        width: '271px',
        height: '240px',
        margin: '0 auto',
        padding: theme.spacing(1.5),
        paddingTop: theme.spacing(3),
        boxSizing: 'border-box',
        position: 'relative',
        boxShadow: '0px 0px 0px 3px rgba(255,255,255,1)',
    },
    infoBoxItem: {
        color: 'white',
        borderBottom: '1px solid white',
        marginBottom: '10px',
    },
    infoBoxCloseButton: {
        color: 'white',
        position: 'absolute',
        top: 0,
        right: 0,
    },
    infoBoxInformation: {
        fontWeight: 'bold',
        fontSize: '1.15rem',
    },
}));

interface KoluluBoxInfoBoxProps {
    onClose: () => void;
    orderId: OrderId;
}

const KoluluBoxInfoBox = (props: KoluluBoxInfoBoxProps) => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Box className={classes.root}>
            <IconButton className={classes.infoBoxCloseButton} onClick={props.onClose}>
                <CancelOutlinedIcon fontSize={'small'} />
            </IconButton>
            <Box className={classes.infoBoxItem}>
                <Link href={'tel:' + Config.tenantConfig.contact.phone} style={{ color: 'white' }}>
                    <Typography className={classes.infoBoxInformation}>
                        {Config.tenantConfig.contact.phoneFormatted}
                    </Typography>
                    <Typography style={{marginBottom: '5px'}}>
                        {t('use_order.kolula_box.call_us')}
                    </Typography>
                </Link>
            </Box>
            <Box className={classes.infoBoxItem}>
                <Link component={RouterLink} to={Routes.makeReportDamageUrl(props.orderId)} color={'inherit'}>
                    <Typography className={classes.infoBoxInformation}>
                        {t('use_order.kolula_box.report_damage')}
                    </Typography>
                    <Typography style={{marginBottom: '5px'}}>
                        {t('use_order.kolula_box.use_our_form')}
                    </Typography>
                </Link>
            </Box>
            <Box className={classes.infoBoxItem}>
                <Link component={RouterLink} to={Routes.makeOrderFaqsUrl(props.orderId)} color={'inherit'}>
                    <Typography className={classes.infoBoxInformation}>
                        {t('use_order.kolula_box.hints_and_tips')}
                    </Typography>
                    <Typography style={{marginBottom: '5px'}}>
                        {t('use_order.kolula_box.look_at_our_faqs')}
                    </Typography>
                </Link>
            </Box>
        </Box>
    );
};

export default KoluluBoxInfoBox;
