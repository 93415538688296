import {Action, handleActions} from 'redux-actions';
import Event from '../action/event';
import {Location, LocationAvailability} from '../types/location';
import {AvailabilitiesFetchedPayload, LocationsFetchedPayload} from '../action/locationEvent';

export interface LocationState {
    locations: Location[];
    availabilities: Record<number, LocationAvailability>;
}

export const initialState: LocationState = {
    locations: [],
    availabilities: {},
};

export const reducer = handleActions<LocationState, any>(
    {
        [Event.Location.locationsFetched.toString()]:
            (state, action: Action<LocationsFetchedPayload>): LocationState => {
                return {
                    ...state,
                    locations: action.payload.locations,
                };
            },
        [Event.Location.availabilitiesFetched.toString()]:
            (state, action: Action<AvailabilitiesFetchedPayload>): LocationState => {
                return {
                    ...state,
                    availabilities: {
                        ...state.availabilities,
                        [action.payload.locationId]: action.payload.locationAvailability,
                    },
                };
            },
    },
    initialState,
);
