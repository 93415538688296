import {createAction} from 'redux-actions';
import {Location, LocationAvailability} from '../types/location';

export interface LocationsFetchedPayload {
    locations: Location[];
}

export interface AvailabilitiesFetchedPayload {
    locationId: number;
    locationAvailability: LocationAvailability;
}

const LocationEvent = {
    locationsFetched: createAction<LocationsFetchedPayload>('@@KL_EVT/LOCATIONS_FETCHED'),
    availabilitiesFetched: createAction<AvailabilitiesFetchedPayload>('@@KL_EVT/AVAILABILITIES_FETCHED'),
};

export default LocationEvent;
