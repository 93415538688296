import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import Config from '../util/config';
import Logger from '../util/logger';
import {
    processAvailabilityData, processIndividualRentalData,
    processLocationsData,
    processOptionData,
    processOrderData,
    processReservationData,
} from './processors';
import {Location, LocationAvailability} from '../types/location';
import moment from 'moment';
import {
    CompleteBraintreePaymentData,
    CompleteStripePaymentData,
    CompleteVippsPaymentData,
    CreateNewReservationData,
    InitializePaymentData,
    InitializeStripePaymentData,
    InitializeVippsPaymentData,
} from './types';
import {
    BraintreePaymentInformation,
    Reservation,
    StripePaymentInformation,
    VippsPaymentInformation,
} from '../types/bookingCreation';
import {InternalOrder, InternalOrderId, OrderId, Rental} from '../types/order';
import {IndividualRental} from '../types/individualRental';
import i18n from '../i18n/i18n';

/* eslint-disable */
const axiosInstance = axios.create({
    baseURL: Config.api.baseUrl,
    headers: {
        'X-Api-Key': Config.api.key,
    },
});

const sendApiRequest = async (request: AxiosRequestConfig): Promise<AxiosResponse> => {
    try {
        axiosInstance.defaults.headers.common['X-Language'] = i18n.language;
        return await axiosInstance(request);
    } catch(error) {
        Logger.for('API').error(request.url);
        throw error;
    }
};

const Api = {
    fetchLocations: async (): Promise<Location[]> => {
        const response = await sendApiRequest({
            method: 'get',
            url: 'locations'
        });

        return processLocationsData(response.data);
    },
    fetchAvailabilities: async (locationId: number): Promise<LocationAvailability> => {
        const fromDate = moment().format('YYYY-MM-DD');
        const toDate = moment().add(2, 'month').format('YYYY-MM-DD');

        const response = await sendApiRequest({
            method: 'get',
            url: `/locations/${locationId}/availabilities?dt_from=${fromDate}&dt_to=${toDate}`
        });

        return {
            availabilities: processAvailabilityData(response.data),
            options: processOptionData(response.data),
        };
    },
    createNewReservation: async (data: CreateNewReservationData): Promise<Reservation> => {
        const response = await sendApiRequest({
            method: 'post',
            url: '/reservations',
            data: {
                locationID: data.locationId,
                date_time: data.dateTime,
                reservation_items: data.items.map(item => ({
                    option_id: item.optionId,
                    quantity: item.quantity,
                })),
            },
        });

        return processReservationData(response.data);
    },
    removeReservation: async (reservationId: string): Promise<void> => {
        await sendApiRequest({
            method: 'delete',
            url: `/reservations/${reservationId}`,
        });
    },
    fetchReservationTotals: async (reservationId: string, couponCode: string): Promise<Reservation> => {
        const response = await sendApiRequest({
            method: 'post',
            url: `/reservations/${reservationId}/totals`,
            data: {
                couponCode,
            },
        });

        return processReservationData(response.data);
    },
    initializeBraintreePayment: async (data: InitializePaymentData): Promise<BraintreePaymentInformation> => {
        const response = await sendApiRequest({
            method: 'post',
            url: '/payments/braintree',
            data: {
                contact: {
                    firstname: data.contact.firstName,
                    lastname: data.contact.lastName,
                    email: data.contact.email,
                    telephone: '+' + data.contact.phone,
                    newsletter_consent: data.contact.newsletterConsent,
                    usc_customerId: data.contact.uscCustomerId,
                },
                reservation: {
                    number: data.reservationId,
                },
            },
        });

        return {
            paymentStatus: response.data.statusCode,
            orderId: response.data.orderId || null,
            braintreePaymentToken: response.data['braintree-payment-token'] || null,
        };
    },
    initializeVippsPayment: async (data: InitializeVippsPaymentData): Promise<VippsPaymentInformation> => {
        const response = await sendApiRequest({
            method: 'post',
            url: '/payments/vipps',
            data: {
                contact: {
                    firstname: data.contact.firstName,
                    lastname: data.contact.lastName,
                    email: data.contact.email,
                    telephone: '+' + data.contact.phone,
                    newsletter_consent: data.contact.newsletterConsent,
                    usc_customerId: data.contact.uscCustomerId,
                },
                reservation: {
                    number: data.reservationId,
                },
                returnUrl: data.returnUrl,
            },
        });

        return {
            paymentStatus: response.data.statusCode,
            orderId: response.data.orderId || null,
            token: response.data.token || null,
            checkoutFrontendUrl: response.data.checkoutFrontendUrl || null,
        };
    },
    initializeStripePayment: async (data: InitializeStripePaymentData): Promise<StripePaymentInformation> => {
        const response = await sendApiRequest({
            method: 'post',
            url: '/payments/stripe',
            data: {
                contact: {
                    firstname: data.contact.firstName,
                    lastname: data.contact.lastName,
                    email: data.contact.email,
                    telephone: '+' + data.contact.phone,
                    newsletter_consent: data.contact.newsletterConsent,
                    usc_customerId: data.contact.uscCustomerId,
                },
                reservation: {
                    number: data.reservationId,
                },
                returnUrl: data.returnUrl,
            },
        });
        return {
            paymentStatus: response.data.statusCode,
            orderId: response.data.orderId || null,
            token: response.data.token || null,
            checkoutFrontendUrl: response.data.checkoutFrontendUrl || null,
        };
    },
    completeBraintreePayment: async (data: CompleteBraintreePaymentData): Promise<OrderId> => {
        const response = await sendApiRequest({
            method: 'post',
            url: '/payments/braintree/execute',
            data: {
                reservation: {
                    number: data.reservationId,
                },
                paymentNonce: data.braintreeNonce,
                deviceData: data.braintreeDeviceData,
            },
        });

        return response.data.orderId;
    },
    completeVippsPayment: async (data: CompleteVippsPaymentData): Promise<any> => {
        const response = await sendApiRequest({
            method: 'post',
            url: '/payments/vipps/execute',
            data: {
                reservation: {
                    number: data.reservationId,
                },
            },
        });

        return response.data.orderId;
    },
    completeStripePayment: async (data: CompleteStripePaymentData): Promise<any> => {
        const response = await sendApiRequest({
            method: 'post',
            url: '/payments/stripe/execute',
            data: {
                reservation: {
                    number: data.reservationId,
                },
            },
        });

        return response.data.orderId;
    },
    fetchOrder: async (internalOrderId: InternalOrderId): Promise<InternalOrder> => {
        const response = await sendApiRequest({
            method: 'get',
            url: `/orders/${internalOrderId}`
        });

        return processOrderData(response.data);
    },
    startOrder: async (internalOrderId: OrderId, items: string[], resourcesCount?: number): Promise<Rental> => {
        const response = await sendApiRequest({
            method: 'post',
            url: `/rentals`,
            data: {
                orderId: internalOrderId,
                orderType: 'Regiondo',
                orderItems: items,
                resourcesCount: resourcesCount || undefined,
            },
        });

        return {
            id: response.data.rentalId,
            token: response.data.token,
            doors: response.data.doors.map((doorData: any) => ({
                door: Number(doorData.kolula_door_id),
                option: doorData.ticket_option,
            })),
            startedAt: response.data.startedAt ? new Date(response.data.startedAt * 1000) : null,
        };
    },
    finishOrder: async (rentalId: string, token: string): Promise<void> => {
        await sendApiRequest({
            method: 'delete',
            url: `/rentals/${rentalId}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    },
    openDoor: async (locationId: number, door: number, token: string): Promise<void> => {
        await sendApiRequest({
            method: 'put',
            url: `/locations/${locationId}/doors/${door}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    },
    reportDamage: async (message: string, doors: number[], token: string): Promise<void> => {
        await sendApiRequest({
            method: 'post',
            url: '/damage-reports',
            headers: {
                Authorization: `Bearer ${token}`
            },
            data: {
                message,
                doors,
            },
        })
    },
    verifyIndividualRental: async (locationId: number, door: number, password: string): Promise<IndividualRental> => {
        const response = await sendApiRequest({
            method: 'post',
            url: '/leasing/check',
            data: {
                locationId,
                doorId: door,
                password,
            },
        });

        return processIndividualRentalData(response.data);
    },
};

export default Api;
