import KolulaIndexedDB, {
    INDEX_ORDER_ID,
    OBJECT_STORE_ORDERS,
    OBJECT_STORE_PERSONAL_DATA,
    OBJECT_STORE_STARTED_ORDERS,
} from './KolulaIndexedDB';
import {IDBPersonalData} from './types';
import {InternalOrder, InternalOrderId, Order, OrderId} from '../types/order';
import moment from 'moment';

const orderIsNotExpired = (order: Order) =>
    moment(order.startDateTime).add(1, 'days').toDate() >= new Date();

class KolulaStorageManager {
    private db: KolulaIndexedDB;

    constructor() {
        this.db = new KolulaIndexedDB();
        this.getPersonalData = this.getPersonalData.bind(this);
        this.setPersonalData = this.setPersonalData.bind(this);
        this.upsertInternalOrder = this.upsertInternalOrder.bind(this);
        this.deleteOrdersByOrderId = this.deleteOrdersByOrderId.bind(this);
        this.getActiveOrders = this.getActiveOrders.bind(this);
        this.getOrder = this.getOrder.bind(this);
        this.setOrderStartedFlag = this.setOrderStartedFlag.bind(this);
        this.hasOrderStartedFlag = this.hasOrderStartedFlag.bind(this);
    }

    public async getPersonalData(): Promise<IDBPersonalData|null> {
        const documents = await this.db.getAll(OBJECT_STORE_PERSONAL_DATA);
        return documents.length > 0 ? documents[0] : null;
    }

    public async setPersonalData(data: IDBPersonalData): Promise<void> {
        await this.db.put(OBJECT_STORE_PERSONAL_DATA, data);
    }

    public async upsertInternalOrder(internalOrder: InternalOrder): Promise<void> {
        await this.deleteOrdersByOrderId(internalOrder[0].orderId);

        for (const orderData of internalOrder) {
            await this.db.put(OBJECT_STORE_ORDERS, orderData);
        }
    }

    public async getActiveOrders(): Promise<Order[]> {
        const allOrders: InternalOrder = await this.db.getAll(OBJECT_STORE_ORDERS);
        return allOrders.filter(
            order => !order.rental?.finishedAt && orderIsNotExpired(order),
        );
    }

    public async setOrderStartedFlag(orderId: OrderId): Promise<void> {
        await this.db.put(OBJECT_STORE_STARTED_ORDERS, { id: orderId, started: true });
    }

    public async hasOrderStartedFlag(orderId: OrderId): Promise<boolean> {
        const flagEntry = await this.db.get(OBJECT_STORE_STARTED_ORDERS, orderId);
        return Boolean(flagEntry && flagEntry.started);
    }

    /**
     * Deletes all orders with the given internal orderId
     *
     * @param internalOrderId
     */
    private async deleteOrdersByOrderId(internalOrderId: InternalOrderId): Promise<void> {
        const documents = await this.db.getAllByIndex(OBJECT_STORE_ORDERS, INDEX_ORDER_ID, internalOrderId);
        for (const document of documents) {
            await this.db.delete(OBJECT_STORE_ORDERS, document.id);
        }
    }

    private async getOrder(orderId: OrderId): Promise<Order|null> {
        return await this.db.get(OBJECT_STORE_ORDERS, orderId) || null;
    }
}

export default KolulaStorageManager;
