import { Action, handleActions } from 'redux-actions';
import moment from 'moment';
import {
    BoardTypeActivatedPayload,
    BoardTypeCountChangedPayload,
    BoardTypeDeactivatedPayload,
    BookingCreationCanceledPayload,
    BookingCreationCompletedPayload,
    BookingCreationStartedPayload, BraintreePaymentInitializedPayload,
    CouponRedemptionLoadingPayload,
    DateSelectedPayload,
    DurationSelectedPayload,
    EmailChangedPayload,
    FirstNameChangedPayload,
    LastNameChangedPayload,
    NewsletterConsentChangedPayload,
    PaddlerCountSelectedPayload,
    PaymentCompletedPayload,
    PaymentCompletionFailedPayload,
    PaymentInitializationFailedPayload,
    PaymentInitializationRetryStartedPayload,
    PaymentTypeChangedPayload,
    PersonalDataChangedPayload,
    PhoneChangedPayload,
    ReservationCreatedPayload,
    ReservationRemovedPayload,
    StripePaymentInitializedPayload,
    TimeSelectedPayload,
    UscCustomerIdChangedPayload,
    VippsPaymentInitializedPayload,
} from '../action/bookingCreationEvent';
import Event from '../action/event';
import {
    BraintreePaymentToken,
    PersonalData,
    PersonalDataValidation,
    Reservation,
    StripeToken,
    VippsToken,
} from '../types/bookingCreation';
import { isValidEmail, isValidPhone, isValidUscCustomerId } from '../util/validation';
import { OrderId } from '../types/order';
import Config from '../util/config';

export interface BookingCreationSelection {
    date: moment.Moment | null;
    time: string;
    duration: number;
    paddlerCount: number;
}

export interface BookingCreationState {
    locationId: number | null;
    multipleBoardTypes: boolean;
    partner: 'usc' | null;
    step: number;
    nextEnabled: boolean;
    selection: BookingCreationSelection;
    boardSelection: Record<string, number>;
    personalData: PersonalData;
    personalDataValidation: PersonalDataValidation;
    reservation: null | Reservation;
    payment: {
        provider: 'braintree' | 'vipps' | 'stripe' | null;
        paymentType: string;
        paymentInitFailed: boolean;
        orderId: null | OrderId;
        braintree: {
            paymentToken: null | BraintreePaymentToken;
        };
        vipps: {
            token: null | VippsToken;
            checkoutFrontendUrl: null | string;
        };
        stripe: {
            token: null | StripeToken;
            checkoutFrontendUrl: null | string;
        };
    };
    loading: {
        couponRedemption: boolean;
        paymentCompletion: boolean;
    };
}

export const initialState: BookingCreationState = {
    locationId: null,
    multipleBoardTypes: false,
    partner: null,
    step: 0,
    nextEnabled: false,
    selection: {
        date: null,
        time: '',
        duration: 0,
        paddlerCount: 1,
    },
    boardSelection: {},
    personalData: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        newsletterConsent: false,
    },
    personalDataValidation: {
        firstName: false,
        lastName: false,
        phone: false,
        email: false,
        uscCustomerId: false,
    },
    reservation: null,
    payment: {
        provider: null,
        paymentType: '',
        paymentInitFailed: false,
        orderId: null,
        braintree: {
            paymentToken: null,
        },
        vipps: {
            token: null,
            checkoutFrontendUrl: null,
        },
        stripe: {
            token: null,
            checkoutFrontendUrl: null,
        },
    },
    loading: {
        couponRedemption: false,
        paymentCompletion: false,
    },
};

const calculateTotalBoardSelectionCount = (boardSelection: Record<string, number>) =>
    Object.values(boardSelection).reduce((acc, value) => acc + value, 0);

export const reducer = handleActions<BookingCreationState, any>(
    {
        [Event.BookingCreation.bookingCreationStarted.toString()]:
            (state, action: Action<BookingCreationStartedPayload>): BookingCreationState => {
                return {
                    ...initialState,
                    locationId: action.payload.locationId,
                    multipleBoardTypes: action.payload.multipleBoardTypes,
                    partner: action.payload.partner || null,
                    personalData: {
                        ...initialState.personalData,
                        uscCustomerId: action.payload.partner === 'usc' ? '' : undefined,
                    },
                };
            },
        [Event.BookingCreation.bookingCreationCanceled.toString()]:
            (state, action: Action<BookingCreationCanceledPayload>): BookingCreationState => {
                return initialState;
            },
        [Event.BookingCreation.navigatedToNextStep.toString()]:
            (state, action: Action<{}>): BookingCreationState => {
                const totalSelectionCount = calculateTotalBoardSelectionCount(state.boardSelection);
                const paymentProvider = Config.tenantConfig.paymentProvider;

                let nextStep = state.step + 1;
                
                if (state.step === 0 && !state.multipleBoardTypes) {
                    // If we are on the first step and there is only one board type, skip the second step
                    nextStep = state.step + 2;
                } else if (state.step === 3 && paymentProvider && paymentProvider.length === 1) {
                    // If we are on the payment step and there is only one payment type configured, skip the next step
                    nextStep = state.step + 2;
                }

                const nextEnabled = nextStep === 3 ||
                    (nextStep === 2 && isPersonalDataValid(validatePersonalData(state.personalData))) ||
                    (nextStep === 1 && totalSelectionCount === state.selection.paddlerCount);

                return {
                    ...state,
                    step: nextStep,
                    nextEnabled: nextEnabled,
                };
            },
        [Event.BookingCreation.navigatedToPreviousStep.toString()]:
            (state, action: Action<{}>): BookingCreationState => {
                const previousStep = state.step === 2 && !state.multipleBoardTypes
                    ? state.step - 2
                    : state.step - 1;

                const resetBoardSelection = previousStep === 0;

                return {
                    ...state,
                    step: previousStep,
                    boardSelection: resetBoardSelection ? initialState.boardSelection : state.boardSelection,
                    nextEnabled: true,
                };
            },
        [Event.BookingCreation.dateSelected.toString()]:
            (state, action: Action<DateSelectedPayload>): BookingCreationState => {
                return {
                    ...state,
                    nextEnabled: false,
                    selection: {
                        ...initialState.selection,
                        date: action.payload.date,
                    },
                };
            },
        [Event.BookingCreation.timeSelected.toString()]:
            (state, action: Action<TimeSelectedPayload>): BookingCreationState => {
                return {
                    ...state,
                    nextEnabled: false,
                    selection: {
                        ...state.selection,
                        time: action.payload.time,
                        duration: 0,
                        paddlerCount: 1,
                    },
                };
            },
        [Event.BookingCreation.durationSelected.toString()]:
            (state, action: Action<DurationSelectedPayload>): BookingCreationState => {
                return {
                    ...state,
                    nextEnabled: Boolean(action.payload.duration),
                    selection: {
                        ...state.selection,
                        duration: action.payload.duration,
                        paddlerCount: 1,
                    },
                };
            },
        [Event.BookingCreation.paddlerCountSelected.toString()]:
            (state, action: Action<PaddlerCountSelectedPayload>): BookingCreationState => {
                return {
                    ...state,
                    selection: {
                        ...state.selection,
                        paddlerCount: action.payload.paddlerCount,
                    },
                };
            },
        [Event.BookingCreation.boardTypeActivated.toString()]:
            (state, action: Action<BoardTypeActivatedPayload>): BookingCreationState => {
                const newBoardSelection = {
                    ...state.boardSelection,
                    [action.payload.id]: 1,
                };

                const totalSelectionCount = calculateTotalBoardSelectionCount(newBoardSelection);

                return {
                    ...state,
                    boardSelection: newBoardSelection,
                    nextEnabled: state.step === 1
                        ? totalSelectionCount === state.selection.paddlerCount
                        : state.nextEnabled,
                };
            },
        [Event.BookingCreation.boardTypeDeactivated.toString()]:
            (state, action: Action<BoardTypeDeactivatedPayload>): BookingCreationState => {
                const { [action.payload.id]: _, ...newBoardSelection } = state.boardSelection;
                const totalSelectionCount = calculateTotalBoardSelectionCount(newBoardSelection);

                return {
                    ...state,
                    boardSelection: newBoardSelection,
                    nextEnabled: state.step === 1
                        ? totalSelectionCount === state.selection.paddlerCount
                        : state.nextEnabled,
                };
            },
        [Event.BookingCreation.boardTypeCountChanged.toString()]:
            (state, action: Action<BoardTypeCountChangedPayload>): BookingCreationState => {
                const newBoardSelection = {
                    ...state.boardSelection,
                    [action.payload.id]: action.payload.count,
                };

                const totalSelectionCount = calculateTotalBoardSelectionCount(newBoardSelection);

                return {
                    ...state,
                    boardSelection: {
                        ...state.boardSelection,
                        [action.payload.id]: action.payload.count,
                    },
                    nextEnabled: state.step === 1
                        ? totalSelectionCount === state.selection.paddlerCount
                        : state.nextEnabled,
                };
            },
        [Event.BookingCreation.reservationCreated.toString()]:
            (state, action: Action<ReservationCreatedPayload>): BookingCreationState => {
                return {
                    ...state,
                    reservation: action.payload.reservation,
                };
            },
        [Event.BookingCreation.reservationRemoved.toString()]:
            (state, action: Action<ReservationRemovedPayload>): BookingCreationState => {
                return {
                    ...state,
                    reservation: null,
                };
            },
        [Event.BookingCreation.firstNameChanged.toString()]:
            (state, action: Action<FirstNameChangedPayload>): BookingCreationState => {
                const personalData = {
                    ...state.personalData,
                    firstName: action.payload.firstName.trim(),
                };
                const validation = validatePersonalData(personalData);

                return {
                    ...state,
                    personalData,
                    personalDataValidation: validation,
                    nextEnabled: isPersonalDataValid(validation),
                };
            },
        [Event.BookingCreation.lastNameChanged.toString()]:
            (state, action: Action<LastNameChangedPayload>): BookingCreationState => {
                const personalData = {
                    ...state.personalData,
                    lastName: action.payload.lastName.trim(),
                };
                const validation = validatePersonalData(personalData);

                return {
                    ...state,
                    personalData,
                    personalDataValidation: validation,
                    nextEnabled: isPersonalDataValid(validation),
                };
            },
        [Event.BookingCreation.phoneChanged.toString()]:
            (state, action: Action<PhoneChangedPayload>): BookingCreationState => {
                const personalData = {
                    ...state.personalData,
                    phone: action.payload.phone.trim(),
                };
                const validation = validatePersonalData(personalData);

                return {
                    ...state,
                    personalData,
                    personalDataValidation: validation,
                    nextEnabled: isPersonalDataValid(validation),
                };
            },
        [Event.BookingCreation.emailChanged.toString()]:
            (state, action: Action<EmailChangedPayload>): BookingCreationState => {
                const personalData = {
                    ...state.personalData,
                    email: action.payload.email.trim(),
                };
                const validation = validatePersonalData(personalData);

                return {
                    ...state,
                    personalData,
                    personalDataValidation: validation,
                    nextEnabled: isPersonalDataValid(validation),
                };
            },
        [Event.BookingCreation.newsletterConsentChanged.toString()]:
            (state, action: Action<NewsletterConsentChangedPayload>): BookingCreationState => {
                return {
                    ...state,
                    personalData: {
                        ...state.personalData,
                        newsletterConsent: action.payload.newsletterConsent,
                    },
                };
            },
        [Event.BookingCreation.paymentTypeChanged.toString()]:
            (state, action: Action<PaymentTypeChangedPayload>): BookingCreationState => {
                return {
                    ...state,
                    payment: {
                        ...state.payment,
                        paymentType: action.payload.paymentType,
                    },
                };
            },
        [Event.BookingCreation.uscCustomerIdChanged.toString()]:
            (state, action: Action<UscCustomerIdChangedPayload>): BookingCreationState => {
                const personalData = {
                    ...state.personalData,
                    uscCustomerId: action.payload.uscCustomerId.trim(),
                };
                const validation = validatePersonalData(personalData);

                return {
                    ...state,
                    personalData,
                    personalDataValidation: validation,
                    nextEnabled: isPersonalDataValid(validation),
                };
            },
        [Event.BookingCreation.personalDataChanged.toString()]:
            (state, action: Action<PersonalDataChangedPayload>): BookingCreationState => {
                const personalData = {
                    ...state.personalData,
                    ...action.payload,
                };

                return {
                    ...state,
                    personalData,
                    personalDataValidation: validatePersonalData(personalData),
                };
            },
        [Event.BookingCreation.couponRedemptionLoading.toString()]:
            (state, action: Action<CouponRedemptionLoadingPayload>): BookingCreationState => {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        couponRedemption: action.payload.loading,
                    },
                };
            },
        [Event.BookingCreation.braintreePaymentInitialized.toString()]:
            (state, action: Action<BraintreePaymentInitializedPayload>): BookingCreationState => {
                return {
                    ...state,
                    payment: {
                        ...state.payment,
                        provider: 'braintree',
                        braintree: {
                            paymentToken: action.payload.braintreePaymentToken,
                        },
                    },
                };
            },
        [Event.BookingCreation.vippsPaymentInitialized.toString()]:
            (state, action: Action<VippsPaymentInitializedPayload>): BookingCreationState => {
                return {
                    ...state,
                    payment: {
                        ...state.payment,
                        provider: 'vipps',
                        vipps: {
                            token: action.payload.vippsToken,
                            checkoutFrontendUrl: action.payload.checkoutFrontendUrl,
                        },
                    },
                };
            },
        [Event.BookingCreation.stripePaymentInitialized.toString()]:
            (state, action: Action<StripePaymentInitializedPayload>): BookingCreationState => {
                return {
                    ...state,
                    payment: {
                        ...state.payment,
                        provider: 'stripe',
                        stripe: {
                            token: action.payload.stripeToken,
                            checkoutFrontendUrl: action.payload.checkoutFrontendUrl,
                        },
                    },
                };
            },
        [Event.BookingCreation.paymentInitializationFailed.toString()]:
            (state, action: Action<PaymentInitializationFailedPayload>): BookingCreationState => {
                return {
                    ...state,
                    payment: {
                        ...state.payment,
                        paymentInitFailed: true,
                    },
                };
            },
        [Event.BookingCreation.paymentInitializationRetryStarted.toString()]:
            (state, action: Action<PaymentInitializationRetryStartedPayload>): BookingCreationState => {
                return {
                    ...state,
                    payment: {
                        ...state.payment,
                        paymentInitFailed: false,
                    },
                };
            },
        [Event.BookingCreation.paymentCompletionBegan.toString()]:
            (state, action: Action<PaymentCompletedPayload>): BookingCreationState => {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        paymentCompletion: true,
                    },
                };
            },
        [Event.BookingCreation.paymentCompleted.toString()]:
            (state, action: Action<PaymentCompletedPayload>): BookingCreationState => {
                return {
                    ...state,
                    payment: {
                        ...state.payment,
                        orderId: action.payload.orderId,
                    },
                    loading: {
                        ...state.loading,
                        paymentCompletion: false,
                    },
                };
            },
        [Event.BookingCreation.paymentCompletionFailed.toString()]:
            (state, action: Action<PaymentCompletionFailedPayload>): BookingCreationState => {
                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        paymentCompletion: false,
                    },
                };
            },
        [Event.BookingCreation.bookingCreationCompleted.toString()]:
            (state, action: Action<BookingCreationCompletedPayload>): BookingCreationState => {
                return initialState;
            },
    },
    initialState,
);

// @todo validating usc customer id like that is probably something that easily breaks at some point
// @todo this function should actually get the information whether this is a USC booking or not
const validatePersonalData = (data: PersonalData): PersonalDataValidation => {
    const firstNameValid = data.firstName.length > 0;
    const lastNameValid = data.lastName.length > 0;
    const phoneValid = data.phone.length > 0 && isValidPhone(data.phone);
    const emailValid = data.email.length > 0 && isValidEmail(data.email);
    const uscCustomerIdValid = data.uscCustomerId === undefined
        || (data.uscCustomerId.length > 0 && isValidUscCustomerId(data.uscCustomerId));

    return {
        firstName: firstNameValid,
        lastName: lastNameValid,
        phone: phoneValid,
        email: emailValid,
        uscCustomerId: uscCustomerIdValid,
    };
};

const isPersonalDataValid = (validation: PersonalDataValidation): boolean => {
    return validation.firstName
        && validation.lastName
        && validation.phone
        && validation.email
        && validation.uscCustomerId;
};
