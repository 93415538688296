import ErrorTracker from '../../util/errorTracker';
import Logger from '../../util/logger';
import KolulaStorageManager from '../../storage/KolulaStorageManager';
import {OrderId} from '../../types/order';
import {call} from 'redux-saga/effects';

export const setOrderStartedFlag = function*(orderId: OrderId) {
    try {
        const kolulaStorageManager = new KolulaStorageManager();
        yield call(kolulaStorageManager.setOrderStartedFlag, orderId);
    } catch (error) {
        ErrorTracker.trackException(error);
        Logger.error(error);
    }
};
