import React from 'react';
import {Menu, MenuItem} from '@material-ui/core';
import KolulaIndexedDB from '../../../storage/KolulaIndexedDB';

interface SettingsMenuProps {
    anchorEl?: Element;
    open: boolean;
    onClose: () => void;
}

const SettingsMenu = (props: SettingsMenuProps) => {

    const resetKolulaDatabase = async () => {
        props.onClose();
        await KolulaIndexedDB.deleteDatabase();
        window.location.reload();
    };

    return (
        <Menu open={props.open} onClose={props.onClose} anchorEl={props.anchorEl}>
            <MenuItem onClick={resetKolulaDatabase}>
                Daten zurücksetzen
            </MenuItem>
        </Menu>
    );
};

export default SettingsMenu;
