import {call, fork, put, select, take} from 'redux-saga/effects';
import Command from '../action/command';
import {Action} from 'redux-actions';
import Api from '../api';
import Logger from '../util/logger';
import {onEnqueueErrorSnackbar, onEnqueueSuccessSnackbar} from './enqueueSnackbarFlow';
import {getI18n} from 'react-i18next';
import {ReportDamagePayload} from '../action/orderCommand';
import Selector from '../selector';
import {Rental} from '../types/order';
import Event from '../action/event';
import ErrorTracker from '../util/errorTracker';

const onReportDamage = function*(claim: string, doors: number[]) {
    const rental: Rental|null = yield select(Selector.Order.rental);

    if (!rental) {
        yield fork(onEnqueueErrorSnackbar, getI18n().t('report_damage.error.report_damage'));
        return;
    }

    yield put(Event.Order.reportingDamageBegan({}));

    try {
        yield call(Api.reportDamage, claim, doors, rental.token);
        yield fork(onEnqueueSuccessSnackbar, getI18n().t('report_damage.success.report_damage'), 12000);
        yield put(Event.Order.damageReported({}));
        return;
    } catch (error) {
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);
        yield fork(onEnqueueErrorSnackbar, getI18n().t('report_damage.error.report_damage'));
    }

    yield put(Event.Order.reportingDamageFailed({}));
};

export function* reportDamageFlow() {
    while (true) {
        const action: Action<ReportDamagePayload> = yield take(Command.Order.reportDamage.toString());

        yield fork(onReportDamage, action.payload.claim, action.payload.doors);
    }
}
