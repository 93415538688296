import React from 'react';
import {Button, ButtonProps, CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    loader: {
        position: 'absolute',
    },
}));

interface LoaderButtonProps extends ButtonProps {
    loading?: boolean;
}

const LoaderButton = (props: LoaderButtonProps) => {
    const classes = useStyles();

    const { loading, children, ...buttonProps } = props;

    return (
        <Button {...buttonProps} disabled={props.disabled || loading}>
            {children}
            {loading && <CircularProgress size={24} className={classes.loader} color={'secondary'} />}
        </Button>
    );
};

export default LoaderButton;
