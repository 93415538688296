/* eslint-disable no-console */

const Logger = {
    log: console.log,
    error: console.error,
    warn: console.warn,
    for: (channel: string) => ({
        log: bindChannelTo(channel, console.log),
        error: bindChannelTo(channel, console.error),
        warn: bindChannelTo(channel, console.warn),
    }),
};

const bindChannelTo = (channel: string, loggingFunction: any) =>
    loggingFunction.bind(undefined, `%c[${channel}]`, 'font-weight: bold');

export default Logger;
