
const HttpStatusCodes = {
    OK: 200,

    BadRequest: 400,
    PreconditionFailed: 412,

    InternalServerError: 500,
};

export default HttpStatusCodes;
