import React, {useState} from 'react';
import {Box, IconButton, Link, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import Routes from '../../routes';
import {Link as RouterLink} from 'react-router-dom';
import LanguageSwitcher from './components/LanguageSwitcher';
import Logo from '../layout/components/Logo';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SettingsMenu from './components/SettingsMenu';
import Config from '../../util/config';

const couponUrl = 'https://damo.regiondo.de/bookingwidget/vendor/16417/id/93627';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '16px',
        color: 'white',
        backgroundColor: 'var(--dark-background-color)',
        height: '100%',
        boxSizing: 'border-box',
        overflow: 'auto',
    },
    hintPageBoxOne: {
        marginTop: theme.spacing(4),
        display: 'grid',
        justifyItems: 'center',
        marginBottom: theme.spacing(4),
    },
    hintPageBoxTwo: {
        display: 'grid',
        justifyItems: 'center',
    },
    link: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: 'white',
        textTransform: 'uppercase',
    },
    settingsButtons: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: 'white',
    },
}));

const TermsOfServicePage = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [settingsMenuAnchor, setSettingsMenuAnchor] = useState<HTMLButtonElement|undefined>(undefined);

    const openSettingsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setSettingsMenuAnchor(event.currentTarget);
    };

    return (
        <Box className={classes.root}>
            <Logo center={true} />

            {/* Temporarily disabled */}
            <IconButton onClick={openSettingsMenu} className={classes.settingsButtons} style={{ display: 'none' }}>
                <MoreVertIcon />
            </IconButton>
            <SettingsMenu
                anchorEl={settingsMenuAnchor}
                open={Boolean(settingsMenuAnchor)}
                onClose={() => setSettingsMenuAnchor(undefined)}
            />

            <Box className={classes.hintPageBoxOne}>
                <Link style={{marginBottom: '11px'}} component={RouterLink} to={Routes.faqsPath}>
                    <Typography className={classes.link}>
                        {t('hints.all_faqs')}
                    </Typography>
                </Link>
                <Link style={{margin: '11px 0'}} component={RouterLink} to={Routes.tipsPath}>
                    <Typography className={classes.link}>
                        {t('hints.notes_and_tips')}
                    </Typography>
                </Link>
                <Link style={{margin: '11px 0'}} component={RouterLink} to={Routes.pricePath}>
                    <Typography className={classes.link}>
                        {t('hints.price_overview')}
                    </Typography>
                </Link>
                {Config.tenant === 'kolula' && (
                    <Link style={{margin: '11px 0'}} target={'_blank'} href={couponUrl}>
                        <Typography className={classes.link}>
                            {t('hints.buy_coupon')}
                        </Typography>
                    </Link>
                )}
                <Link style={{margin: '11px 0'}} component={RouterLink} to={Routes.addOrderPath}>
                    <Typography className={classes.link}>
                        {t('hints.more_tickets')}
                    </Typography>
                </Link>
            </Box>
            <Box className={classes.hintPageBoxTwo}>
                <Link style={{marginBottom: '11px'}} component={RouterLink} to={Routes.termsOfServicePath}>
                    <Typography className={classes.link}>
                        {t('hints.terms_of_service')}
                    </Typography>
                </Link>
                <Link style={{margin: '11px 0'}} component={RouterLink} to={Routes.dataProtectionPath}>
                    <Typography className={classes.link}>
                        {t('hints.data_protection')}
                    </Typography>
                </Link>
                <Link style={{marginTop: '11px'}} component={RouterLink} to={Routes.imprintPath}>
                    <Typography className={classes.link}>
                        {t('hints.imprint')}
                    </Typography>
                </Link>
            </Box>
            <LanguageSwitcher />
        </Box>
    );
};

export default TermsOfServicePage;
