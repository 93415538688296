import {Box, Typography} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import KolulaSupLogo from './KolulaSupLogo';

const useStyles = makeStyles(theme => ({
    root: {
        padding: `${theme.spacing(2)}px 0`,
        [theme.breakpoints.up('lg')]: {
            padding: `${theme.spacing(5)}px 0 ${theme.spacing(4)}px 0`,
        },
    },
    header: {
        marginTop: theme.spacing(2),
        color: 'white',
    },
}));

interface HeaderProps {
    text?: string;
}

const Header = (props: HeaderProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <KolulaSupLogo center={true} />
            {props.text && (
                <Typography variant={'h1'} className={classes.header}>
                    {props.text}
                </Typography>
            )}
        </Box>
    );
};

export default Header;
