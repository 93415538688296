import {call, delay, fork, put, take} from 'redux-saga/effects';
import KolulaStorageManager from '../storage/KolulaStorageManager';
import {InternalOrder, Order} from '../types/order';
import Event from '../action/event';
import Command from '../action/command';
import {onFetchOrder} from './fetchOrderFlow';
import ErrorTracker from '../util/errorTracker';
import Logger from '../util/logger';

export const onLoadOrders = function*() {
    try {
        const kolulaStorageManager = new KolulaStorageManager();
        const orders: Order[] = yield call(kolulaStorageManager.getActiveOrders);
        yield put(Event.Order.ordersLoaded({ orders }));

        const pendingOrders: string[] = [...new Set(orders
            .filter(order => order.status === 'processing')
            .map(order => order.orderId),
        ) as any];

        for (const orderId of pendingOrders) {
            // Zero retries to prevent flooding the API
            yield fork(onFetchOrder, orderId);
            yield delay(500);
        }
    } catch (error) {
        ErrorTracker.trackException(error);
        Logger.error(error);

        // Just act as if there are no orders to be loaded if IndexedDB is not available
        yield put(Event.Order.ordersLoaded({ orders: [] }));
    }
};

export const onPersistOrders = function*(internalOrder: InternalOrder) {
    try {
        const kolulaStorageManager = new KolulaStorageManager();
        yield call(kolulaStorageManager.upsertInternalOrder, internalOrder);
    } catch (error) {
        ErrorTracker.trackException(error);
        Logger.error(error);
    }
};

export function* loadOrdersFlow() {
    while (true) {
        yield take(Command.Order.loadOrders.toString());
        yield fork(onLoadOrders);
    }
}
