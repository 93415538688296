import {InternalOrder, InternalOrderId} from '../types/order';
import {call, fork, put, take} from 'redux-saga/effects';
import Api from '../api';
import Logger from '../util/logger';
import {onEnqueueErrorSnackbar, onEnqueueWarningSnackbar} from './enqueueSnackbarFlow';
import {getI18n} from 'react-i18next';
import Event from '../action/event';
import {onPersistOrders} from './manageOrdersFlow';
import Command from '../action/command';
import {FetchOrderPayload} from '../action/orderCommand';
import {Action} from 'redux-actions';
import ErrorTracker from '../util/errorTracker';

export const onFetchOrder = function*(internalOrderId: InternalOrderId, showFinishedWarning?: boolean): any {
    yield put(Event.Order.orderFetchingBegan({}));

    try {
        // A single regiondo order might equal multiple kolula orders (1:n relation)
        const internalOrder: InternalOrder = yield call(Api.fetchOrder, internalOrderId);

        yield call(onPersistOrders, internalOrder);

        if (internalOrder[0]?.rental?.finishedAt) {
            if (showFinishedWarning) {
                yield fork(onEnqueueWarningSnackbar, getI18n().t('orders.warning.load_finished_order'));
            }

            yield put(Event.Order.orderFetchingFailed({}));
            return;
        }

        yield put(Event.Order.orderFetched({ internalOrder }));
    } catch (error) {
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);

        if (error.response && error.response.status === 403) {
            yield fork(onEnqueueErrorSnackbar, getI18n().t('orders.error.order_expired'));
        } else {
            yield fork(onEnqueueErrorSnackbar, getI18n().t('orders.error.fetch_order'));
        }

        yield put(Event.Order.orderFetchingFailed({}));
    }
};

export function* fetchOrderFlow() {
    while (true) {
        const action: Action<FetchOrderPayload> = yield take(Command.Order.fetchOrder.toString());

        yield fork(onFetchOrder, action.payload.internalOrderId, action.payload.showFinishedWarning);
    }
}
