import {ReduxState} from '../reducer';
import {Location, LocationAvailability, Option} from '../types/location';

const stateKey = 'location';

const LocationSelector = {
    locations: (state: ReduxState) => state[stateKey].locations,

    makeLocation: (locationId: number) => (state: ReduxState): Location|null =>
        state[stateKey].locations.find(location => location.id === locationId) || null,
    makeLocationAvailabilities: (locationId: number) => (state: ReduxState): LocationAvailability|null =>
        state[stateKey].availabilities[locationId] || null,
    makeLocationAccessories: (locationId: number) => (state: ReduxState): string[] =>
        state[stateKey].locations.find(location => location.id === locationId)?.accessories || [],
    makeOption: (locationId: number, optionId: string) => (state: ReduxState): Option|null => {
        if (!state[stateKey].availabilities[locationId]) {
            return null;
        }

        for (const optionsData of Object.values(state[stateKey].availabilities[locationId].options)) {
            const option = Object.values(optionsData).find(optionData => optionData.id === optionId);
            if (option) {
                return option;
            }
        }

        return null;
    },
};

export default LocationSelector;
