const MuiButton = {
    root: {
        borderRadius: 0,
        fontSize: '16px',
        '&$contained': {
            border: '1px solid white',
        },
        '&$contained:not($containedPrimary):not($containedSecondary)': {
            fontWeight: 700,
        },
        '&$contained:not($containedPrimary):not($containedSecondary):not($disabled)': {
            backgroundColor: 'white',
        },
        '&$text': {
            color: 'white',
        },
    },
};

export default MuiButton;
