import {Action, handleActions} from 'redux-actions';
import Event from '../action/event';
import {
    DamageReportedPayload,
    DoorsVisibleTimestampResetPayload,
    IndividualRentalCompletedPayload,
    IndividualRentalVerificationBeganPayload,
    IndividualRentalVerificationCompletedPayload,
    IndividualRentalVerificationFailedPayload,
    OrderFetchedPayload,
    OrderFetchingBeganPayload,
    OrderFetchingFailedPayload,
    OrderFinalizedPayload,
    OrderFinishedPayload,
    OrderStartedPayload,
    OrderStartingBeganPayload,
    OrderStartingFailedPayload,
    OrdersLoadedPayload, ReportingDamageBeganPayload, ReportingDamageFailedPayload,
} from '../action/orderEvent';
import {Order, Rental} from '../types/order';
import {IndividualRental} from '../types/individualRental';

export interface IndividualRentalState {
    loading: boolean;
    data: null|IndividualRental;
}

export interface OrderState {
    orders: Record<string, Order>;
    ordersLoaded: boolean;
    rental: Rental|null;
    fetchingOrder: boolean;
    startingOrder: boolean;
    doorsVisibleTimestamp: Date|null;
    orderFinished: boolean;
    reportingDamage: boolean;
    individualRental: IndividualRentalState;
}

export const initialState: OrderState = {
    orders: {},
    ordersLoaded: false,
    rental: null,
    fetchingOrder: false,
    startingOrder: false,
    doorsVisibleTimestamp: null,
    orderFinished: false,
    reportingDamage: false,
    individualRental: {
        loading: false,
        data: null,
    },
};

export const reducer = handleActions<OrderState, any>(
    {
        [Event.Order.orderFetchingBegan.toString()]:
            (state, action: Action<OrderFetchingBeganPayload>): OrderState => {
                return {
                    ...state,
                    fetchingOrder: true,
                };
            },
        [Event.Order.orderFetched.toString()]:
            (state, action: Action<OrderFetchedPayload>): OrderState => {
                const internalOrderId = action.payload.internalOrder[0].orderId;
                const filteredOrders = Object.keys(state.orders)
                    .filter(id => id.split('_')[0] !== internalOrderId)
                    .reduce((object, key) => {
                        object[key] = state.orders[key];
                        return object;
                    }, {} as Record<string, Order>);

                const newOrders: Record<string, Order> = {};
                action.payload.internalOrder.forEach(order => {
                    newOrders[order.id] = order;
                });

                return {
                    ...state,
                    orders: {
                        ...filteredOrders,
                        ...newOrders,
                    },
                    fetchingOrder: false,
                };
            },
        [Event.Order.orderFetchingFailed.toString()]:
            (state, action: Action<OrderFetchingFailedPayload>): OrderState => {
                return {
                    ...state,
                    fetchingOrder: false,
                };
            },
        [Event.Order.ordersLoaded.toString()]:
            (state, action: Action<OrdersLoadedPayload>): OrderState => {
                const orders: Record<string, Order> = {};
                action.payload.orders.forEach(order => {
                    orders[order.id] = order;
                });

                return {
                    ...state,
                    ordersLoaded: true,
                    orders,
                };
            },
        [Event.Order.orderStartingBegan.toString()]:
            (state, action: Action<OrderStartingBeganPayload>): OrderState => {
                return {
                    ...state,
                    startingOrder: true,
                    doorsVisibleTimestamp: null,
                };
            },
        [Event.Order.orderStarted.toString()]:
            (state, action: Action<OrderStartedPayload>): OrderState => {
                return {
                    ...state,
                    rental: action.payload.rental,
                    startingOrder: false,
                    doorsVisibleTimestamp: action.payload.startedTimestamp,
                };
            },
        [Event.Order.orderStartingFailed.toString()]:
            (state, action: Action<OrderStartingFailedPayload>): OrderState => {
                return {
                    ...state,
                    startingOrder: false,
                    doorsVisibleTimestamp: null,
                };
            },
        [Event.Order.orderFinished.toString()]:
            (state, action: Action<OrderFinishedPayload>): OrderState => {
                return {
                    ...state,
                    orderFinished: true,
                };
            },
        [Event.Order.orderFinalized.toString()]:
            (state, action: Action<OrderFinalizedPayload>): OrderState => {
                const { [action.payload.orderId]: _, ...newOrders} = state.orders;

                return {
                    ...state,
                    rental: null,
                    orders: newOrders,
                };
            },
        [Event.Order.reportingDamageBegan.toString()]:
            (state, action: Action<ReportingDamageBeganPayload>): OrderState => {
                return {
                    ...state,
                    reportingDamage: true,
                };
            },
        [Event.Order.reportingDamageFailed.toString()]:
            (state, action: Action<ReportingDamageFailedPayload>): OrderState => {
                return {
                    ...state,
                    reportingDamage: false,
                };
            },
        [Event.Order.damageReported.toString()]:
            (state, action: Action<DamageReportedPayload>): OrderState => {
                return {
                    ...state,
                    reportingDamage: false,
                };
            },
        [Event.Order.doorsVisibleTimestampReset.toString()]:
            (state, action: Action<DoorsVisibleTimestampResetPayload>): OrderState => {
                return {
                    ...state,
                    doorsVisibleTimestamp: new Date(),
                };
            },
        [Event.Order.individualRentalVerificationBegan.toString()]:
            (state, action: Action<IndividualRentalVerificationBeganPayload>): OrderState => {
                return {
                    ...state,
                    individualRental: {
                        ...state.individualRental,
                        loading: true,
                    },
                };
            },
        [Event.Order.individualRentalVerificationFailed.toString()]:
            (state, action: Action<IndividualRentalVerificationFailedPayload>): OrderState => {
                return {
                    ...state,
                    individualRental: {
                        ...state.individualRental,
                        loading: false,
                    },
                };
            },
        [Event.Order.individualRentalVerificationCompleted.toString()]:
            (state, action: Action<IndividualRentalVerificationCompletedPayload>): OrderState => {
                return {
                    ...state,
                    individualRental: {
                        ...state.individualRental,
                        loading: false,
                        data: action.payload.individualRental,
                    },
                };
            },
        [Event.Order.individualRentalCompleted.toString()]:
            (state, action: Action<IndividualRentalCompletedPayload>): OrderState => {
                return {
                    ...state,
                    individualRental: {
                        ...initialState.individualRental,
                    },
                };
            },
    },
    initialState,
);
