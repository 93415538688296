import React from 'react';
import {Box} from '@material-ui/core';
import Toolbar from './Toolbar';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
    },
    content: {
        width: '100%',
        height: 'calc(100% - 59px)',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
});

interface MainLayoutProps {
    children: React.ReactNode;
}

const MainLayout = (props: MainLayoutProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.content}>
                {props.children}
            </Box>
            <Toolbar />
        </Box>
    );
};

export default MainLayout;
