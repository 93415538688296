import React, {useState} from 'react';
import {Box, Grow, IconButton} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import KolulaBoxDoor from './KolulaBoxDoor';
import KolulaBoxSquare from './KolulaBoxSquare';
import KolulaBoxRectangle from './KolulaBoxRectangle';
import {makeStyles} from '@material-ui/core/styles';
import KoluluBoxInfoBox from './KolulaBoxInfoBox';
import {OrderId} from '../../../types/order';



/* Layout
    9  | 10 | 11 | 12
    5  | 6  | 7  | 8
    1  | 2  | 3  | 4
 */

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'white',
        width: '271px',
        height: '240px',
        margin: '0 auto',
        padding: '12px',
        boxSizing: 'border-box',
        position: 'relative',
    },
    doorWrapper: {
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        position: 'relative',
    },
    info: {
        color: theme.palette.secondary.main,
        width: '30px',
        height: '30px',
        position: 'absolute',
        left: '217px',
    },
    infoBoxWrapper: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
}));

interface KolulaBoxProps {
    orderId?: OrderId;
    activeDoors: number[];
}

const KolulaBox = (props: KolulaBoxProps) => {
    const classes = useStyles();
    const [infoOpen, setInfoOpen] = useState<boolean>(false);

    const enableInfo = Boolean(props.orderId);

    return (
        <Box className={classes.root}>
            <Box className={classes.doorWrapper}>
                <KolulaBoxSquare posX={0} posY={0} />
                <KolulaBoxSquare posX={0} posY={186} />
                <KolulaBoxRectangle posX={93} posY={0} />
                <KolulaBoxDoor
                    top={0}
                    left={0}
                    orientation={'left'}
                    doorNumber={9}
                    active={props.activeDoors.includes(9)}
                />
                <KolulaBoxDoor
                    top={62}
                    left={0}
                    orientation={'right'}
                    doorNumber={5}
                    active={props.activeDoors.includes(5)}
                />
                <KolulaBoxDoor
                    top={124}
                    left={0}
                    orientation={'left'}
                    doorNumber={1}
                    active={props.activeDoors.includes(1)}
                />
                <KolulaBoxDoor
                    top={0}
                    left={62}
                    orientation={'right'}
                    doorNumber={10}
                    active={props.activeDoors.includes(10)}
                />
                <KolulaBoxDoor
                    top={62}
                    left={62}
                    orientation={'left'}
                    doorNumber={6}
                    active={props.activeDoors.includes(6)}
                />
                <KolulaBoxDoor
                    top={124}
                    left={62}
                    orientation={'right'}
                    doorNumber={2}
                    active={props.activeDoors.includes(2)}
                />
                <KolulaBoxDoor
                    top={0}
                    left={124}
                    orientation={'left'}
                    doorNumber={11}
                    active={props.activeDoors.includes(11)}
                />
                <KolulaBoxDoor
                    top={62}
                    left={124}
                    orientation={'right'}
                    doorNumber={7}
                    active={props.activeDoors.includes(7)}
                />
                <KolulaBoxDoor
                    top={124}
                    left={124}
                    orientation={'left'}
                    doorNumber={3}
                    active={props.activeDoors.includes(3)}
                />
                <KolulaBoxDoor
                    top={0}
                    left={186}
                    orientation={'right'}
                    doorNumber={12}
                    active={props.activeDoors.includes(12)}
                />
                <KolulaBoxDoor
                    top={62}
                    left={186}
                    orientation={'left'}
                    doorNumber={8}
                    active={props.activeDoors.includes(8)}
                />
                <KolulaBoxDoor
                    top={124}
                    left={186}
                    orientation={'right'}
                    doorNumber={4}
                    active={props.activeDoors.includes(4)}
                />
                <KolulaBoxRectangle posX={93} posY={186} />
                {enableInfo && (
                    <IconButton className={classes.info} onClick={() => setInfoOpen(true)}>
                        <InfoOutlinedIcon fontSize={'small'} />
                    </IconButton>
                )}
                {!enableInfo && <KolulaBoxSquare posX={217} posY={0} />}
                <KolulaBoxSquare posX={217} posY={186} />
            </Box>
            {enableInfo && (
                <Grow in={infoOpen}>
                    <Box className={classes.infoBoxWrapper}>
                        <KoluluBoxInfoBox onClose={() => setInfoOpen(false)} orderId={props.orderId!} />
                    </Box>
                </Grow>
            )}
        </Box>
    );
};

export default KolulaBox;
