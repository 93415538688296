import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import HintsDialog from './components/HintsDialog';
import {Accordion, AccordionDetails, AccordionSummary, Box, Link, Typography} from '@material-ui/core';
import {Trans, useTranslation} from 'react-i18next';
import {Link as RouterLink} from 'react-router-dom';
import Routes from '../../routes';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(theme => ({
    accordionContainer: {
        marginTop: theme.spacing(2),
    },
    accordion: {
        margin: '0 !important',
        backgroundColor: 'var(--dark-background-color)',
        border: 'none',
        borderBottom: '1px solid #a0a6a7',
        borderRadius: '0 !important',
        color: 'white',
    },
    accordionSummaryContent: {
        margin: '20px 0',
    },
    accordionDetails: {
        display: 'block',
    },
    faqHeader: {
        margin: '0 auto',
        fontWeight: 500,
    },
}));

const Faq1 = () => {
    const {t} = useTranslation();

    return (
        <ol>
            <li>{t('hints.faqs.1.content1')}</li>
            <li>{t('hints.faqs.1.content2')}</li>
            <li>{t('hints.faqs.1.content3')}</li>
        </ol>
    );
};

const Faq2 = () => {
    const {t} = useTranslation();

    return (
        <>
            <p>{t('hints.faqs.2.content1')}</p>
            <p><Link component={RouterLink} to={Routes.tipsPath}>{t('hints.faqs.2.content2')}</Link></p>
            <p>{t('hints.faqs.2.content3')}</p>
        </>
    );
};

const Faq3 = () => {
    return (
        <p>
            <Trans
                i18nKey={'hints.faqs.3.content'}
                components={{
                    mail: <Link href={'mailto:support@kolula.com'} />,
                }}
            />
        </p>
    );
};

const Faq4 = () => {
    const {t} = useTranslation();

    return (
        <>
            <p>{t('hints.faqs.4.content1')}</p>
            <p>{t('hints.faqs.4.content2')}</p>
            <p>{t('hints.faqs.4.content3')}</p>
        </>
    );
};

const Faq5 = () => {
    const {t} = useTranslation();
    const iconStyle = { verticalAlign: 'middle' };

    return (
        <>
            <p>
                <Trans
                    i18nKey={'hints.faqs.5.content1'}
                    components={{
                        icon: <InfoOutlinedIcon fontSize={'small'} color={'secondary'} style={iconStyle} />,
                    }}
                />
            </p>
            <p>{t('hints.faqs.5.content2')}</p>
        </>
    );
};

const Faq6 = () => {
    const {t} = useTranslation();

    return (
        <>
            <p>{t('hints.faqs.6.content1')}</p>
            <p>
                <Trans
                    i18nKey={'hints.faqs.6.content2'}
                    components={{
                        mail: <Link href={'mailto:support@kolula.com'} />,
                    }}
                />
            </p>
        </>
    );
};

const faqs: React.ReactElement[] = [<Faq1 />, <Faq2 />, <Faq3 />, <Faq4 />, <Faq5 />, <Faq6 />];

interface FAQsDialogProps {
    open: boolean;
    onClose?: () => void;
}

const FAQsDialog = (props: FAQsDialogProps) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [expandedFaqIndex, setExpandedFaqIndex] = useState<number>();

    const handleExpandedFaqChange = (faqIndex: number) => {
        setExpandedFaqIndex(expandedFaqIndex === faqIndex ? undefined : faqIndex);
    };

    return (
        <HintsDialog open={props.open} onClose={props.onClose}>
            <Typography variant={'h1'}>FAQs</Typography>

            <Box className={classes.accordionContainer}>
                {faqs.map((faqContent, faqIndex) => (
                    <Accordion
                        className={classes.accordion}
                        elevation={0}
                        key={faqIndex}
                        style={faqIndex === faqs.length - 1 ? { borderBottomWidth: 0 } : undefined}
                        expanded={expandedFaqIndex === faqIndex}
                        onChange={() => handleExpandedFaqChange(faqIndex)}
                    >
                        <AccordionSummary classes={{ content: classes.accordionSummaryContent}}>
                            <Typography variant={'h2'} className={classes.faqHeader}>
                                {t(`hints.faqs.${faqIndex + 1}.header`)}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography variant={'body1'} component={'span'}>
                                {faqContent}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </HintsDialog>
    );
};

export default FAQsDialog;
